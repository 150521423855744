import { CONFIG } from 'src/config-global';

export const FILE_TYPE_OPTIONS = [['folder'], ['txt'], ['zip']];

export const ALERT_TYPE = ['type1', 'type2', 'type3'];
export const DOCS_TYPE = ['NEWS', 'BLOG'];
export const SOURCE_TYPE = ['FCA', 'EBA', 'BCA'];
export const TOPICS_TYPE = ['FCA', 'EBA', 'BCA'];
export const TABS_FILTER_TYPE = ['Pending', 'Assigned', 'All', 'Starred'];
export const HEADING_TEXT = [
  'You will see all unassigned & pending alerts here',
  'You will see all assigned & pending alerts here',
  'You will see all alerts here',
  'You will see all alerts which you or your team has marked as star here',
];
export const ALERT_FLITER_LABEL = [
  'Alert Type',
  'Doc Type',
  'Sources',
  ...(CONFIG.featureToggle.alert.filter.topics ? ['Topics'] : []),
];
export const ALERTS_TYPE_OPTIONS = [ALERT_TYPE, DOCS_TYPE, SOURCE_TYPE, TOPICS_TYPE];
export const TABS = TABS_FILTER_TYPE.map((val, i) => ({
  value: val,
  label: val,
  headingText: HEADING_TEXT[i],
}));
export const GAP_ANALYSIS_FLITER_LABEL = ['Risk Level', 'Impacted Area', 'Owner', 'Task Status'];
export const IMPACT_FLITER_LABEL = ['Product Impacted', 'Business Impacted'];
export const RESPONSE_REQUIRED_LABEL = ['informative', 'actionable'];

export const DOCUMENT_TYPE_REGULATION = [
  'Guidance',
  'Directives',
  'Legislation',
  'Regulations',
  'Regulatory Filings',
];
export const DOCUMENT_TYPE_UPDATE = [
  'Bills',
  'Proposed Rulemaking',
  'Consultation',
  'Discussion Paper',
  'Enforcements',
  'Events',
  'Letters and Comments',
  'Industry standard',
  'News',
  'Notice and circular',
  'Reports',
  'Research and Papers',
  'Others',
];

export const ALERT_TOPICS = [
  'Accounting and auditing',
  'Anti-Bribery/Corruption',
  'Anti-Money Laundering and Countering the Financing of Terrorism',
  'Competition and Antitrust',
  'Blockchain/Crypto',
  'Consumer protection',
  'Corporate Governance',
  'Credit risk',
  'Cybersecurity',
  'Data Governance',
  'Data Protection',
  'Dispute and Litigation',
  'Fraud and Security',
  'Lending',
  'Passporting and supervision of branches',
  'Payment services and electronic money',
  'Privacy',
  'Regulatory Reporting',
  'Sanctions',
  'Supervision',
  'Others',
];
export const READ_UNREAD = ['read', 'unread'];
export const HORIZON_NAMES = [...DOCUMENT_TYPE_REGULATION, ...DOCUMENT_TYPE_UPDATE];

export const DATE_RANGE_OPTIONS = [
  { value: 'Today', code: 'today', label: 'Today' },
  { value: 'Last 7 days', code: 'last_7_days', label: 'Last 7 days' },
  { value: 'Last 14 days', code: 'last_14_days', label: 'Last 14 days' },
  { value: 'Last 30 days', code: 'last_30_days', label: 'Last 30 days' },
  { value: 'Last 3 months', code: 'last_3_months', label: 'Last 3 months' },
  { value: 'Last 6 months', code: 'last_6_months', label: 'Last 6 months' },
  {
    value: 'Choose custom date range',
    code: 'choose_custom_date_range',
    label: 'Choose custom date range',
  },
];

export const IMPACT_AREAS_FLITER_LABEL = [
  'Product',
  'Engineering',
  'Compliance',
  'Security',
  'Fincrime',
  'Finance',
  'Business',
  'Corporate structure',
  'IT Infrastructure',
  'Operations',
];
export const IMPACT_PRODUCT_STATUS_FLITER_LABEL = [
  'Acquiring',
  'Bank Accounts',
  'Business lending',
  'Card Networking system',
  'Cash-Based Payment Methods',
  'Central bank Digital Currencies (CBDC)',
  'Clearing and settlement infrastructure',
  'Consumer Lending',
  'Cross-Border Payments',
  'Crypto-Assets',
  'E-Money',
  'Issuing',
  'Insurance Mediation',
  'Loyalty Schemes',
  'Mobile Payments',
  'Payment Processors',
  'Prepaid',
  'Remittances',
  'Third-party Providers',
];
export const IMPACTED_TABS_FLITER_LABEL = ['Pending', 'Reviewed', 'Starred', 'All'];
export const REQUIREMENT_TABS = IMPACTED_TABS_FLITER_LABEL.map((val, i) => ({
  value: val,
  label: val,
}));
export const LAW_LIBRARY_TABS = ['All', 'Starred'];

export const LAW_LIBRARY_TABS_MAP = [
  { value: 'All', label: 'All' },
  { value: 'Starred', label: 'Starred' },
];
export const MATRIX_OPTIONS = ['G1', 'G2', 'G3', 'G4'];
export const POLICY_STATUS_OPTIONS = ['pending', 'processed', 'failed'];

export const KEY_NOVA_BANK_SPACIFICATION = [
  'document_type',
  'implementation_date',
  'associated_reports',
  'implementation_consultants',
  'discrepancies',
  'topics',
];
export const NOVA_BANK_SPACIFICATION = [
  {
    weight: '1',
    key: 'document_type',
    criteria: 'Commission Information',
    id: '1',
    grade: 'g1',
    label: 'Nature of Diploma',
  },
  {
    weight: '1',
    key: 'document_type',
    criteria: 'CMVM Annual Circular',
    id: '2',
    grade: 'g1',
    label: 'Nature of Diploma',
  },
  {
    weight: '1',
    key: 'document_type',
    criteria: 'Q&A Regulators',
    id: '3',
    grade: 'g1',
    label: 'Nature of Diploma',
  },
  {
    weight: '2',
    key: 'document_type',
    criteria: 'Resolution of the Republic Assembly',
    id: '4',
    grade: 'g2',
    label: 'Nature of Diploma',
  },
  {
    weight: '2',
    key: 'document_type',
    criteria: 'Resolution of the Council of Ministers',
    id: '5',
    grade: 'g2',
    label: 'Nature of Diploma',
  },
  {
    weight: '2',
    key: 'document_type',
    criteria: 'Rectification',
    id: '6',
    grade: 'g2',
    label: 'Nature of Diploma',
  },
  {
    weight: '2',
    key: 'document_type',
    criteria: 'Declaration of Rectification',
    id: '7',
    grade: 'g2',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Circular Letter',
    id: '8',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'EU Decision',
    id: '9',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Directive',
    id: '10',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Delegated Directive',
    id: '11',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Guidelines',
    id: '12',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Ordinance',
    id: '13',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Instruction',
    id: '14',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '4',
    key: 'document_type',
    criteria: 'Recommendation',
    id: '15',
    grade: 'g3',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Law',
    id: '16',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Decree-Law',
    id: '17',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Order',
    id: '18',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Notice',
    id: '19',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Regulatory Standard',
    id: '20',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Regulation',
    id: '21',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'EU Regulation',
    id: '22',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Implementation Regulation',
    id: '23',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '8',
    key: 'document_type',
    criteria: 'Delegated Regulation',
    id: '24',
    grade: 'g4',
    label: 'Nature of Diploma',
  },
  {
    weight: '1',
    key: 'implementation_date',
    criteria: '12 months or more',
    id: '25',
    grade: 'g1',
    label: 'Implementation date',
  },
  {
    weight: '2',
    key: 'implementation_date',
    criteria: '6 to 12 months',
    id: '26',
    grade: 'g2',
    label: 'Implementation date',
  },
  {
    weight: '4',
    key: 'implementation_date',
    criteria: '31 days to 6 months',
    id: '27',
    grade: 'g3',
    label: 'Implementation date',
  },
  {
    weight: '8',
    key: 'implementation_date',
    criteria: 'up to 30 days',
    id: '28',
    grade: 'g4',
    label: 'Implementation date',
  },
  {
    weight: '1',
    key: 'associated_reports',
    criteria: 'No Associated Reports',
    id: '29',
    grade: 'g1',
    label: 'Associated reports',
  },
  {
    weight: '2',
    key: 'associated_reports',
    criteria:
      '(i) There is correspondence with Reports of type "Other" (ii) New Report to entities other than a Regulator',
    id: '30',
    grade: 'g2',
    label: 'Associated reports',
  },
  {
    weight: '4',
    key: 'associated_reports',
    criteria:
      '(i) There is correspondence with Reports of the "Non Core" type (ii) New Report to a Regulator (subject to reassessment if the report is subsequently considered Core)',
    id: '31',
    grade: 'g3',
    label: 'Associated reports',
  },
  {
    weight: '8',
    key: 'associated_reports',
    criteria: 'There is correspondence with Reports of type "Core"',
    id: '32',
    grade: 'g4',
    label: 'Associated reports',
  },
  {
    weight: '2',
    key: 'implementation_consultants',
    criteria: 'No need for DSI or External Entities involvement',
    id: '33',
    grade: 'g2',
    label: 'Involvement of DSI and/or External Entities (implementation consultants)',
  },
  {
    weight: '4',
    key: 'implementation_consultants',
    criteria: 'Need for involvement of DSI OR External Entities',
    id: '34',
    grade: 'g3',
    label: 'Involvement of DSI and/or External Entities (implementation consultants)',
  },
  {
    weight: '8',
    key: 'implementation_consultants',
    criteria: 'Need for DSI and External Entities involvement',
    id: '35',
    grade: 'g4',
    label: 'Involvement of DSI and/or External Entities (implementation consultants)',
  },
  {
    weight: '1',
    key: 'discrepancies',
    criteria: 'No records',
    id: '36',
    grade: 'g1',
    label: 'discrepancies',
  },
  {
    weight: '2',
    key: 'discrepancies',
    criteria: 'Correspondence with non-compliance with DAR-GR knowledge',
    id: '37',
    grade: 'g2',
    label: 'discrepancies',
  },
  {
    weight: '4',
    key: 'discrepancies',
    criteria:
      'Changes to the Diploma and already with records of non-compliances reported in internal control findings (identified by EY, DCOMPL, BdP or JST, ongoing or reopened, risk F2, F3 or F4)',
    id: '38',
    grade: 'g3',
    label: 'discrepancies',
  },
  {
    weight: '8',
    key: 'discrepancies',
    criteria:
      'Amendments to Diplomas subject to Contraventions, Specific Determinations, CMVM or ASF Supervisory Actions and/or OSIs or IMIs',
    id: '39',
    grade: 'g4',
    label: 'discrepancies',
  },
  {
    weight: '1',
    key: 'topics',
    criteria: 'All other subjects',
    id: '40',
    grade: 'g1',
    label: 'Importance or complexity of the Theme (Annually/ joint proposal DAR and DRG)',
  },
  {
    weight: '2',
    key: 'topics',
    criteria: 'Other Investment and Savings Topics Government Data Internal Government',
    id: '41',
    grade: 'g2',
    label: 'Importance or complexity of the Theme (Annually/ joint proposal DAR and DRG)',
  },
  {
    weight: '4',
    key: 'topics',
    criteria:
      'Investor Protection Topics (e.g. MIFID II and retail) Security Information Protection Data ESG Topics that the bank has no experience in, but intends to invest in',
    id: '42',
    grade: 'g3',
    label: 'Importance or complexity of the Theme (Annually/ joint proposal DAR and DRG)',
  },
  {
    weight: '8',
    key: 'topics',
    criteria:
      'Capital credit (includes PARI/PERSI, insolvency cases) Payments (PSD/PSR) AML/CFT Operational resilience',
    id: '43',
    grade: 'g4',
    label: 'Importance or complexity of the Theme (Annually/ joint proposal DAR and DRG)',
  },
];
