import React from 'react';

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Iconify } from 'src/components/iconify';
import { useTranslate } from 'src/locales';

const ErrorBoundaryWrapper = (props) => {
  const { t } = useTranslate();
  return <ErrorBoundary t={t} {...props} />;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Update state with error details
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <Container maxWidth="sm">
          <Box
            sx={{
              textAlign: 'center',
              mt: 8,
              p: 4,
              borderRadius: 1,
              bgcolor: 'background.paper',
              boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 2px 0px',
              border: '1px solid #919EAB1F',
            }}
          >
            <Stack spacing={2} alignItems="center">
              <Iconify
                icon="solar:danger-triangle-bold"
                width={64}
                height={64}
                sx={{ color: 'error.main' }}
              />

              <Typography variant="h4" color="text.primary">
                {t('errorBoundary.title', 'Oops! Something went wrong.')}
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                {t(
                  'errorBoundary.message',
                  "We're sorry, but an error occurred while processing your request."
                )}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="solar:refresh-bold" />}
                onClick={() => {
                  window.location.href = '/';
                }}
                sx={{
                  boxShadow: 'none',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                {t('errorBoundary.reloadButton', 'Reload Page')}
              </Button>
            </Stack>
          </Box>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundaryWrapper;
