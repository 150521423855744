import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthSplitLayout } from 'src/layouts/auth-split';

import { SplashScreen } from 'src/components/loading-screen';

import { GuestGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const Firebase = {
  SignInPage: lazy(() => import('src/pages/auth/firebase/sign-in')),
  SignUpPage: lazy(() => import('src/pages/auth/firebase/sign-up')),
  VerifyPage: lazy(() => import('src/pages/auth/firebase/verify')),
  ResetPasswordPage: lazy(() => import('src/pages/auth/firebase/reset-password')),
  ChangePasswordPage: lazy(() => import('src/pages/auth/firebase/change-password')),
};

const authFirebase = {
  path: 'firebase',
  children: [
    {
      path: 'sign-in',
      element: (
        <GuestGuard>
          <AuthSplitLayout
            section={{
              title: 'auth.welcome',
              subtitle: 'auth.signInSubtitle',
            }}
          >
            <Firebase.SignInPage />
          </AuthSplitLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <AuthSplitLayout
            section={{
              title: 'auth.manageJob',
              subtitle: 'auth.subtitle',
            }}
          >
            <Firebase.SignUpPage />
          </AuthSplitLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'verify',
      element: (
        <AuthSplitLayout
          section={{
            title: 'auth.manageJob',
            subtitle: 'auth.subtitle',
          }}
        >
          <Firebase.VerifyPage />
        </AuthSplitLayout>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <AuthSplitLayout
          section={{
            title: 'auth.manageJob',
            subtitle: 'auth.subtitle',
          }}
        >
          <Firebase.ResetPasswordPage />
        </AuthSplitLayout>
      ),
    },
    {
      path: 'change-password',
      element: (
        <AuthSplitLayout
          section={{
            title: 'auth.manageJob',
            subtitle: 'auth.subtitle',
          }}
        >
          <Firebase.ChangePasswordPage />
        </AuthSplitLayout>
      ),
    },
  ],
};
// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [authFirebase],
  },
];
