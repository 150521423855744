import { useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import Popover from '@mui/material/Popover';

import { ToolbarItem } from './toolbar-item';

export const EmojiBlock = ({ editor, slackEmojis = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    if (emoji.src) {
      // If the emoji is a custom Slack emoji with shortcodes
      const emojiHtml = `<img src="${emoji.src}" alt="${emoji.shortcodes}" class="emoji-inline" style="display: inline-block; vertical-align: middle; width: 1em; height: 1em; margin: 0 0.1em;">`;
      editor.chain().focus().insertContent(emojiHtml).run();
    } else {
      // If it's a native emoji
      editor.chain().focus().insertContent(emoji.native).run();
    }
    handleClose();
  };

  return (
    <>
      <ToolbarItem
        aria-label="Emoji"
        onClick={handleClick}
        icon={
          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-5-7h2a3 3 0 0 0 6 0h2a5 5 0 0 1-10 0zm1-2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
        }
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Picker data={data} theme="light" custom={slackEmojis} onEmojiSelect={handleEmojiSelect} />
      </Popover>
    </>
  );
};
