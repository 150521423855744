import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { signOut } from 'src/auth/context/firebase/action';
import { Iconify } from 'src/components/iconify';
import { NavItem } from 'src/components/nav-section/vertical/nav-item';
import { Box, Divider, Stack } from '@mui/material';
import { LanguagePopover } from './language-popover';
import { USER_TYPE } from 'src/config-global';

// ----------------------------------------------------------------------

export function SignOutButton({ ...other }) {
  const router = useRouter();

  const { checkUserSession, userType } = useAuthContext();

  const handleLogout = useCallback(async () => {
    try {
      await signOut();
      await checkUserSession?.();

      // onClose?.();
      router.refresh();
    } catch (error) {}
  }, [checkUserSession, router]);

  return (
    <Stack sx={{ gap: '4px' }}>
      {/* <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}> */}
      {userType === USER_TYPE[0] && (
        <>
          <LanguagePopover data-slot="localization" type="custom" data={[]} />
          <Divider sx={{ borderTop: '1px' }} />
        </>
      )}

      <Button
        variant="text"
        color="inherit"
        onClick={handleLogout}
        startIcon={<Iconify icon="solar:logout-outline" width={24} height={24} />}
        sx={{
          justifyContent: 'flex-start',
          padding: '4px 8px 4px 12px',
          color: 'var(--swap-dark-light-error, #B71D18)',
          height: '44px',
          '& .MuiButton-startIcon': {
            marginRight: 1,
          },
          fontWeight: 500,
        }}
        {...other}
      >
        Logout
      </Button>
      {/* </Box> */}
    </Stack>
  );
}
