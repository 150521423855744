import { _mock } from '../_mock';

// ----------------------------------------------------------------------

export const countries = [
  {
    timezones: ['America/Aruba'],
    latlng: [12.5, -69.96666666],
    name: 'Aruba',
    country_code: 'AW',
    capital: 'Oranjestad',
    photoUrl: _mock.image.cover(1),
  },
  {
    timezones: ['Asia/Kabul'],
    latlng: [33, 65],
    name: 'Afghanistan',
    country_code: 'AF',
    capital: 'Kabul',
    photoUrl: _mock.image.cover(2),
  },
  {
    timezones: ['Africa/Luanda'],
    latlng: [-12.5, 18.5],
    name: 'Angola',
    country_code: 'AO',
    capital: 'Luanda',
    photoUrl: _mock.image.cover(3),
  },
  {
    timezones: ['Pacific/Efate'],
    latlng: [-16, 167],
    name: 'Vanuatu',
    country_code: 'VU',
    capital: 'Port Vila',
    photoUrl: _mock.image.cover(4),
  },
  {
    timezones: ['Pacific/Wallis'],
    latlng: [-13.3, -176.2],
    name: 'Wallis and Futuna',
    country_code: 'WF',
    capital: 'Mata-Utu',
    photoUrl: _mock.image.cover(5),
  },
  {
    timezones: ['Pacific/Apia'],
    latlng: [-13.58333333, -172.33333333],
    name: 'Samoa',
    country_code: 'WS',
    capital: 'Apia',
    photoUrl: _mock.image.cover(6),
  },
  {
    timezones: ['Asia/Aden'],
    latlng: [15, 48],
    name: 'Yemen',
    country_code: 'YE',
    capital: "Sana'a",
    photoUrl: _mock.image.cover(7),
  },
  {
    timezones: ['Africa/Johannesburg'],
    latlng: [-29, 24],
    name: 'South Africa',
    country_code: 'ZA',
    capital: 'Pretoria',
    photoUrl: _mock.image.cover(8),
  },
  {
    timezones: ['Africa/Lusaka'],
    latlng: [-15, 30],
    name: 'Zambia',
    country_code: 'ZM',
    capital: 'Lusaka',
    photoUrl: _mock.image.cover(9),
  },
  {
    timezones: ['Africa/Harare'],
    latlng: [-20, 30],
    name: 'Zimbabwe',
    country_code: 'ZW',
    capital: 'Harare',
    photoUrl: _mock.image.cover(10),
  },
];
export const countriesAlpha3 = [
  {
    label: 'Afghanistan',
    frenchShortName: "Afghanistan (l')",
    code: 'AF',
    alpha3Code: 'AFG',
    numeric: 4,
  },
  {
    label: 'Albania',
    frenchShortName: "Albanie (l')",
    code: 'AL',
    alpha3Code: 'ALB',
    numeric: 8,
  },
  {
    label: 'Algeria',
    frenchShortName: "Algérie (l')",
    code: 'DZ',
    alpha3Code: 'DZA',
    numeric: 12,
  },
  {
    label: 'American Samoa',
    frenchShortName: 'Samoa américaines (les)',
    code: 'AS',
    alpha3Code: 'ASM',
    numeric: 16,
  },
  {
    label: 'Andorra',
    frenchShortName: "Andorre (l')",
    code: 'AD',
    alpha3Code: 'AND',
    numeric: 20,
  },
  {
    label: 'Angola',
    frenchShortName: "Angola (l')",
    code: 'AO',
    alpha3Code: 'AGO',
    numeric: 24,
  },
  {
    label: 'Anguilla',
    frenchShortName: 'Anguilla',
    code: 'AI',
    alpha3Code: 'AIA',
    numeric: 660,
  },
  {
    label: 'Antarctica',
    frenchShortName: "Antarctique (l')",
    code: 'AQ',
    alpha3Code: 'ATA',
    numeric: 10,
  },
  {
    label: 'Antigua and Barbuda',
    frenchShortName: 'Antigua-et-Barbuda',
    code: 'AG',
    alpha3Code: 'ATG',
    numeric: 28,
  },
  {
    label: 'Argentina',
    frenchShortName: "Argentine (l')",
    code: 'AR',
    alpha3Code: 'ARG',
    numeric: 32,
  },
  {
    label: 'Armenia',
    frenchShortName: "Arménie (l')",
    code: 'AM',
    alpha3Code: 'ARM',
    numeric: 51,
  },
  {
    label: 'Aruba',
    frenchShortName: 'Aruba',
    code: 'AW',
    alpha3Code: 'ABW',
    numeric: 533,
  },
  {
    label: 'Australia',
    frenchShortName: "Australie (l')",
    code: 'AU',
    alpha3Code: 'AUS',
    numeric: 36,
  },
  {
    label: 'Austria',
    frenchShortName: "Autriche (l')",
    code: 'AT',
    alpha3Code: 'AUT',
    numeric: 40,
  },
  {
    label: 'Azerbaijan',
    frenchShortName: "Azerbaïdjan (l')",
    code: 'AZ',
    alpha3Code: 'AZE',
    numeric: 31,
  },
  {
    label: 'Bahamas (the)',
    frenchShortName: 'Bahamas (les)',
    code: 'BS',
    alpha3Code: 'BHS',
    numeric: 44,
  },
  {
    label: 'Bahrain',
    frenchShortName: 'Bahreïn',
    code: 'BH',
    alpha3Code: 'BHR',
    numeric: 48,
  },
  {
    label: 'Bangladesh',
    frenchShortName: 'Bangladesh (le)',
    code: 'BD',
    alpha3Code: 'BGD',
    numeric: 50,
  },
  {
    label: 'Barbados',
    frenchShortName: 'Barbade (la)',
    code: 'BB',
    alpha3Code: 'BRB',
    numeric: 52,
  },
  {
    label: 'Belarus',
    frenchShortName: 'Bélarus (le)',
    code: 'BY',
    alpha3Code: 'BLR',
    numeric: 112,
  },
  {
    label: 'Belgium',
    frenchShortName: 'Belgique (la)',
    code: 'BE',
    alpha3Code: 'BEL',
    numeric: 56,
  },
  {
    label: 'Belize',
    frenchShortName: 'Belize (le)',
    code: 'BZ',
    alpha3Code: 'BLZ',
    numeric: 84,
  },
  {
    label: 'Benin',
    frenchShortName: 'Bénin (le)',
    code: 'BJ',
    alpha3Code: 'BEN',
    numeric: 204,
  },
  {
    label: 'Bermuda',
    frenchShortName: 'Bermudes (les)',
    code: 'BM',
    alpha3Code: 'BMU',
    numeric: 60,
  },
  {
    label: 'Bhutan',
    frenchShortName: 'Bhoutan (le)',
    code: 'BT',
    alpha3Code: 'BTN',
    numeric: 64,
  },
  {
    label: 'Bolivia (Plurinational State of)',
    frenchShortName: 'Bolivie (État plurinational de)',
    code: 'BO',
    alpha3Code: 'BOL',
    numeric: 68,
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    frenchShortName: 'Bonaire, Saint-Eustache et Saba',
    code: 'BQ',
    alpha3Code: 'BES',
    numeric: 535,
  },
  {
    label: 'Bosnia and Herzegovina',
    frenchShortName: 'Bosnie-Herzégovine (la)',
    code: 'BA',
    alpha3Code: 'BIH',
    numeric: 70,
  },
  {
    label: 'Botswana',
    frenchShortName: 'Botswana (le)',
    code: 'BW',
    alpha3Code: 'BWA',
    numeric: 72,
  },
  {
    label: 'Bouvet Island',
    frenchShortName: "Bouvet (l'Île)",
    code: 'BV',
    alpha3Code: 'BVT',
    numeric: 74,
  },
  {
    label: 'Brazil',
    frenchShortName: 'Brésil (le)',
    code: 'BR',
    alpha3Code: 'BRA',
    numeric: 76,
  },
  {
    label: 'British Indian Ocean Territory (the)',
    frenchShortName: "Indien (le Territoire britannique de l'océan)",
    code: 'IO',
    alpha3Code: 'IOT',
    numeric: 86,
  },
  {
    label: 'Brunei Darussalam',
    frenchShortName: 'Brunéi Darussalam (le)',
    code: 'BN',
    alpha3Code: 'BRN',
    numeric: 96,
  },
  {
    label: 'Bulgaria',
    frenchShortName: 'Bulgarie (la)',
    code: 'BG',
    alpha3Code: 'BGR',
    numeric: 100,
  },
  {
    label: 'Burkina Faso',
    frenchShortName: 'Burkina Faso (le)',
    code: 'BF',
    alpha3Code: 'BFA',
    numeric: 854,
  },
  {
    label: 'Burundi',
    frenchShortName: 'Burundi (le)',
    code: 'BI',
    alpha3Code: 'BDI',
    numeric: 108,
  },
  {
    label: 'Cabo Verde',
    frenchShortName: 'Cabo Verde',
    code: 'CV',
    alpha3Code: 'CPV',
    numeric: 132,
  },
  {
    label: 'Cambodia',
    frenchShortName: 'Cambodge (le)',
    code: 'KH',
    alpha3Code: 'KHM',
    numeric: 116,
  },
  {
    label: 'Cameroon',
    frenchShortName: 'Cameroun (le)',
    code: 'CM',
    alpha3Code: 'CMR',
    numeric: 120,
  },
  {
    label: 'Canada',
    frenchShortName: 'Canada (le)',
    code: 'CA',
    alpha3Code: 'CAN',
    numeric: 124,
  },
  {
    label: 'Cayman Islands (the)',
    frenchShortName: 'Caïmans (les Îles)',
    code: 'KY',
    alpha3Code: 'CYM',
    numeric: 136,
  },
  {
    label: 'Central African Republic (the)',
    frenchShortName: 'République centrafricaine (la)',
    code: 'CF',
    alpha3Code: 'CAF',
    numeric: 140,
  },
  {
    label: 'Chad',
    frenchShortName: 'Tchad (le)',
    code: 'TD',
    alpha3Code: 'TCD',
    numeric: 148,
  },
  {
    label: 'Chile',
    frenchShortName: 'Chili (le)',
    code: 'CL',
    alpha3Code: 'CHL',
    numeric: 152,
  },
  {
    label: 'China',
    frenchShortName: 'Chine (la)',
    code: 'CN',
    alpha3Code: 'CHN',
    numeric: 156,
  },
  {
    label: 'Christmas Island',
    frenchShortName: "Christmas (l'Île)",
    code: 'CX',
    alpha3Code: 'CXR',
    numeric: 162,
  },
  {
    label: 'Cocos (Keeling) Islands (the)',
    frenchShortName: 'Cocos (les Îles)/ Keeling (les Îles)',
    code: 'CC',
    alpha3Code: 'CCK',
    numeric: 166,
  },
  {
    label: 'Colombia',
    frenchShortName: 'Colombie (la)',
    code: 'CO',
    alpha3Code: 'COL',
    numeric: 170,
  },
  {
    label: 'Comoros (the)',
    frenchShortName: 'Comores (les)',
    code: 'KM',
    alpha3Code: 'COM',
    numeric: 174,
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    frenchShortName: 'Congo (la République démocratique du)',
    code: 'CD',
    alpha3Code: 'COD',
    numeric: 180,
  },
  {
    label: 'Congo (the)',
    frenchShortName: 'Congo (le)',
    code: 'CG',
    alpha3Code: 'COG',
    numeric: 178,
  },
  {
    label: 'Cook Islands (the)',
    frenchShortName: 'Cook (les Îles)',
    code: 'CK',
    alpha3Code: 'COK',
    numeric: 184,
  },
  {
    label: 'Costa Rica',
    frenchShortName: 'Costa Rica (le)',
    code: 'CR',
    alpha3Code: 'CRI',
    numeric: 188,
  },
  {
    label: 'Croatia',
    frenchShortName: 'Croatie (la)',
    code: 'HR',
    alpha3Code: 'HRV',
    numeric: 191,
  },
  {
    label: 'Cuba',
    frenchShortName: 'Cuba',
    code: 'CU',
    alpha3Code: 'CUB',
    numeric: 192,
  },
  {
    label: 'Curaçao',
    frenchShortName: 'Curaçao',
    code: 'CW',
    alpha3Code: 'CUW',
    numeric: 531,
  },
  {
    label: 'Cyprus',
    frenchShortName: 'Chypre',
    code: 'CY',
    alpha3Code: 'CYP',
    numeric: 196,
  },
  {
    label: 'Czechia',
    frenchShortName: 'Tchéquie (la)',
    code: 'CZ',
    alpha3Code: 'CZE',
    numeric: 203,
  },
  {
    label: "Côte d'Ivoire",
    frenchShortName: "Côte d'Ivoire (la)",
    code: 'CI',
    alpha3Code: 'CIV',
    numeric: 384,
  },
  {
    label: 'Denmark',
    frenchShortName: 'Danemark (le)',
    code: 'DK',
    alpha3Code: 'DNK',
    numeric: 208,
  },
  {
    label: 'Djibouti',
    frenchShortName: 'Djibouti',
    code: 'DJ',
    alpha3Code: 'DJI',
    numeric: 262,
  },
  {
    label: 'Dominica',
    frenchShortName: 'Dominique (la)',
    code: 'DM',
    alpha3Code: 'DMA',
    numeric: 212,
  },
  {
    label: 'Dominican Republic (the)',
    frenchShortName: 'dominicaine (la République)',
    code: 'DO',
    alpha3Code: 'DOM',
    numeric: 214,
  },
  {
    label: 'Ecuador',
    frenchShortName: "Équateur (l')",
    code: 'EC',
    alpha3Code: 'ECU',
    numeric: 218,
  },
  {
    label: 'Egypt',
    frenchShortName: "Égypte (l')",
    code: 'EG',
    alpha3Code: 'EGY',
    numeric: 818,
  },
  {
    label: 'El Salvador',
    frenchShortName: 'El Salvador',
    code: 'SV',
    alpha3Code: 'SLV',
    numeric: 222,
  },
  {
    label: 'Equatorial Guinea',
    frenchShortName: 'Guinée équatoriale (la)',
    code: 'GQ',
    alpha3Code: 'GNQ',
    numeric: 226,
  },
  {
    label: 'Eritrea',
    frenchShortName: "Érythrée (l')",
    code: 'ER',
    alpha3Code: 'ERI',
    numeric: 232,
  },
  {
    label: 'Estonia',
    frenchShortName: "Estonie (l')",
    code: 'EE',
    alpha3Code: 'EST',
    numeric: 233,
  },
  {
    label: 'Eswatini',
    frenchShortName: "Eswatini (l')",
    code: 'SZ',
    alpha3Code: 'SWZ',
    numeric: 748,
  },
  {
    label: 'Ethiopia',
    frenchShortName: "Éthiopie (l')",
    code: 'ET',
    alpha3Code: 'ETH',
    numeric: 231,
  },
  {
    label: 'Falkland Islands (the) [Malvinas]',
    frenchShortName: 'Falkland (les Îles)/Malouines (les Îles)',
    code: 'FK',
    alpha3Code: 'FLK',
    numeric: 238,
  },
  {
    label: 'Faroe Islands (the)',
    frenchShortName: 'Féroé (les Îles)',
    code: 'FO',
    alpha3Code: 'FRO',
    numeric: 234,
  },
  {
    label: 'Fiji',
    frenchShortName: 'Fidji (les)',
    code: 'FJ',
    alpha3Code: 'FJI',
    numeric: 242,
  },
  {
    label: 'Finland',
    frenchShortName: 'Finlande (la)',
    code: 'FI',
    alpha3Code: 'FIN',
    numeric: 246,
  },
  {
    label: 'France',
    frenchShortName: 'France (la)',
    code: 'FR',
    alpha3Code: 'FRA',
    numeric: 250,
  },
  {
    label: 'French Guiana',
    frenchShortName: 'Guyane française (la )',
    code: 'GF',
    alpha3Code: 'GUF',
    numeric: 254,
  },
  {
    label: 'French Polynesia',
    frenchShortName: 'Polynésie française (la)',
    code: 'PF',
    alpha3Code: 'PYF',
    numeric: 258,
  },
  {
    label: 'French Southern Territories (the)',
    frenchShortName: 'Terres australes françaises (les)',
    code: 'TF',
    alpha3Code: 'ATF',
    numeric: 260,
  },
  {
    label: 'Gabon',
    frenchShortName: 'Gabon (le)',
    code: 'GA',
    alpha3Code: 'GAB',
    numeric: 266,
  },
  {
    label: 'Gambia (the)',
    frenchShortName: 'Gambie (la)',
    code: 'GM',
    alpha3Code: 'GMB',
    numeric: 270,
  },
  {
    label: 'Georgia',
    frenchShortName: 'Géorgie (la)',
    code: 'GE',
    alpha3Code: 'GEO',
    numeric: 268,
  },
  {
    label: 'Germany',
    frenchShortName: "Allemagne (l')",
    code: 'DE',
    alpha3Code: 'DEU',
    numeric: 276,
  },
  {
    label: 'Ghana',
    frenchShortName: 'Ghana (le)',
    code: 'GH',
    alpha3Code: 'GHA',
    numeric: 288,
  },
  {
    label: 'Gibraltar',
    frenchShortName: 'Gibraltar',
    code: 'GI',
    alpha3Code: 'GIB',
    numeric: 292,
  },
  {
    label: 'Greece',
    frenchShortName: 'Grèce (la)',
    code: 'GR',
    alpha3Code: 'GRC',
    numeric: 300,
  },
  {
    label: 'Greenland',
    frenchShortName: 'Groenland (le)',
    code: 'GL',
    alpha3Code: 'GRL',
    numeric: 304,
  },
  {
    label: 'Grenada',
    frenchShortName: 'Grenade (la)',
    code: 'GD',
    alpha3Code: 'GRD',
    numeric: 308,
  },
  {
    label: 'Guadeloupe',
    frenchShortName: 'Guadeloupe (la)',
    code: 'GP',
    alpha3Code: 'GLP',
    numeric: 312,
  },
  {
    label: 'Guam',
    frenchShortName: 'Guam',
    code: 'GU',
    alpha3Code: 'GUM',
    numeric: 316,
  },
  {
    label: 'Guatemala',
    frenchShortName: 'Guatemala (le)',
    code: 'GT',
    alpha3Code: 'GTM',
    numeric: 320,
  },
  {
    label: 'Guernsey',
    frenchShortName: 'Guernesey',
    code: 'GG',
    alpha3Code: 'GGY',
    numeric: 831,
  },
  {
    label: 'Guinea',
    frenchShortName: 'Guinée (la)',
    code: 'GN',
    alpha3Code: 'GIN',
    numeric: 324,
  },
  {
    label: 'Guinea-Bissau',
    frenchShortName: 'Guinée-Bissau (la)',
    code: 'GW',
    alpha3Code: 'GNB',
    numeric: 624,
  },
  {
    label: 'Guyana',
    frenchShortName: 'Guyana (le)',
    code: 'GY',
    alpha3Code: 'GUY',
    numeric: 328,
  },
  {
    label: 'Haiti',
    frenchShortName: 'Haïti',
    code: 'HT',
    alpha3Code: 'HTI',
    numeric: 332,
  },
  {
    label: 'Heard Island and McDonald Islands',
    frenchShortName: "Heard-et-Îles MacDonald (l'Île)",
    code: 'HM',
    alpha3Code: 'HMD',
    numeric: 334,
  },
  {
    label: 'Holy See (the)',
    frenchShortName: 'Saint-Siège (le)',
    code: 'VA',
    alpha3Code: 'VAT',
    numeric: 336,
  },
  {
    label: 'Honduras',
    frenchShortName: 'Honduras (le)',
    code: 'HN',
    alpha3Code: 'HND',
    numeric: 340,
  },
  {
    label: 'Hong Kong',
    frenchShortName: 'Hong Kong',
    code: 'HK',
    alpha3Code: 'HKG',
    numeric: 344,
  },
  {
    label: 'Hungary',
    frenchShortName: 'Hongrie (la)',
    code: 'HU',
    alpha3Code: 'HUN',
    numeric: 348,
  },
  {
    label: 'Iceland',
    frenchShortName: "Islande (l')",
    code: 'IS',
    alpha3Code: 'ISL',
    numeric: 352,
  },
  {
    label: 'India',
    frenchShortName: "Inde (l')",
    code: 'IN',
    alpha3Code: 'IND',
    numeric: 356,
  },
  {
    label: 'Indonesia',
    frenchShortName: "Indonésie (l')",
    code: 'ID',
    alpha3Code: 'IDN',
    numeric: 360,
  },
  {
    label: 'Iran (Islamic Republic of)',
    frenchShortName: "Iran (République Islamique d')",
    code: 'IR',
    alpha3Code: 'IRN',
    numeric: 364,
  },
  {
    label: 'Iraq',
    frenchShortName: "Iraq (l')",
    code: 'IQ',
    alpha3Code: 'IRQ',
    numeric: 368,
  },
  {
    label: 'Ireland',
    frenchShortName: "Irlande (l')",
    code: 'IE',
    alpha3Code: 'IRL',
    numeric: 372,
  },
  {
    label: 'Isle of Man',
    frenchShortName: 'Île de Man',
    code: 'IM',
    alpha3Code: 'IMN',
    numeric: 833,
  },
  {
    label: 'Israel',
    frenchShortName: 'Israël',
    code: 'IL',
    alpha3Code: 'ISR',
    numeric: 376,
  },
  {
    label: 'Italy',
    frenchShortName: "Italie (l')",
    code: 'IT',
    alpha3Code: 'ITA',
    numeric: 380,
  },
  {
    label: 'Jamaica',
    frenchShortName: 'Jamaïque (la)',
    code: 'JM',
    alpha3Code: 'JAM',
    numeric: 388,
  },
  {
    label: 'Japan',
    frenchShortName: 'Japon (le)',
    code: 'JP',
    alpha3Code: 'JPN',
    numeric: 392,
  },
  {
    label: 'Jersey',
    frenchShortName: 'Jersey',
    code: 'JE',
    alpha3Code: 'JEY',
    numeric: 832,
  },
  {
    label: 'Jordan',
    frenchShortName: 'Jordanie (la)',
    code: 'JO',
    alpha3Code: 'JOR',
    numeric: 400,
  },
  {
    label: 'Kazakhstan',
    frenchShortName: 'Kazakhstan (le)',
    code: 'KZ',
    alpha3Code: 'KAZ',
    numeric: 398,
  },
  {
    label: 'Kenya',
    frenchShortName: 'Kenya (le)',
    code: 'KE',
    alpha3Code: 'KEN',
    numeric: 404,
  },
  {
    label: 'Kiribati',
    frenchShortName: 'Kiribati',
    code: 'KI',
    alpha3Code: 'KIR',
    numeric: 296,
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    frenchShortName: 'Corée (la République populaire démocratique de)',
    code: 'KP',
    alpha3Code: 'PRK',
    numeric: 408,
  },
  {
    label: 'Korea (the Republic of)',
    frenchShortName: 'Corée (la République de)',
    code: 'KR',
    alpha3Code: 'KOR',
    numeric: 410,
  },
  {
    label: 'Kuwait',
    frenchShortName: 'Koweït (le)',
    code: 'KW',
    alpha3Code: 'KWT',
    numeric: 414,
  },
  {
    label: 'Kyrgyzstan',
    frenchShortName: 'Kirghizistan (le)',
    code: 'KG',
    alpha3Code: 'KGZ',
    numeric: 417,
  },
  {
    label: "Lao People's Democratic Republic (the)",
    frenchShortName: 'Lao (la République démocratique populaire)',
    code: 'LA',
    alpha3Code: 'LAO',
    numeric: 418,
  },
  {
    label: 'Latvia',
    frenchShortName: 'Lettonie (la)',
    code: 'LV',
    alpha3Code: 'LVA',
    numeric: 428,
  },
  {
    label: 'Lebanon',
    frenchShortName: 'Liban (le)',
    code: 'LB',
    alpha3Code: 'LBN',
    numeric: 422,
  },
  {
    label: 'Lesotho',
    frenchShortName: 'Lesotho (le)',
    code: 'LS',
    alpha3Code: 'LSO',
    numeric: 426,
  },
  {
    label: 'Liberia',
    frenchShortName: 'Libéria (le)',
    code: 'LR',
    alpha3Code: 'LBR',
    numeric: 430,
  },
  {
    label: 'Libya',
    frenchShortName: 'Libye (la)',
    code: 'LY',
    alpha3Code: 'LBY',
    numeric: 434,
  },
  {
    label: 'Liechtenstein',
    frenchShortName: 'Liechtenstein (le)',
    code: 'LI',
    alpha3Code: 'LIE',
    numeric: 438,
  },
  {
    label: 'Lithuania',
    frenchShortName: 'Lituanie (la)',
    code: 'LT',
    alpha3Code: 'LTU',
    numeric: 440,
  },
  {
    label: 'Luxembourg',
    frenchShortName: 'Luxembourg (le)',
    code: 'LU',
    alpha3Code: 'LUX',
    numeric: 442,
  },
  {
    label: 'Macao',
    frenchShortName: 'Macao',
    code: 'MO',
    alpha3Code: 'MAC',
    numeric: 446,
  },
  {
    label: 'Madagascar',
    frenchShortName: 'Madagascar',
    code: 'MG',
    alpha3Code: 'MDG',
    numeric: 450,
  },
  {
    label: 'Malawi',
    frenchShortName: 'Malawi (le)',
    code: 'MW',
    alpha3Code: 'MWI',
    numeric: 454,
  },
  {
    label: 'Malaysia',
    frenchShortName: 'Malaisie (la)',
    code: 'MY',
    alpha3Code: 'MYS',
    numeric: 458,
  },
  {
    label: 'Maldives',
    frenchShortName: 'Maldives (les)',
    code: 'MV',
    alpha3Code: 'MDV',
    numeric: 462,
  },
  {
    label: 'Mali',
    frenchShortName: 'Mali (le)',
    code: 'ML',
    alpha3Code: 'MLI',
    numeric: 466,
  },
  {
    label: 'Malta',
    frenchShortName: 'Malte',
    code: 'MT',
    alpha3Code: 'MLT',
    numeric: 470,
  },
  {
    label: 'Marshall Islands (the)',
    frenchShortName: 'Marshall (les Îles)',
    code: 'MH',
    alpha3Code: 'MHL',
    numeric: 584,
  },
  {
    label: 'Martinique',
    frenchShortName: 'Martinique (la)',
    code: 'MQ',
    alpha3Code: 'MTQ',
    numeric: 474,
  },
  {
    label: 'Mauritania',
    frenchShortName: 'Mauritanie (la)',
    code: 'MR',
    alpha3Code: 'MRT',
    numeric: 478,
  },
  {
    label: 'Mauritius',
    frenchShortName: 'Maurice',
    code: 'MU',
    alpha3Code: 'MUS',
    numeric: 480,
  },
  {
    label: 'Mayotte',
    frenchShortName: 'Mayotte',
    code: 'YT',
    alpha3Code: 'MYT',
    numeric: 175,
  },
  {
    label: 'Mexico',
    frenchShortName: 'Mexique (le)',
    code: 'MX',
    alpha3Code: 'MEX',
    numeric: 484,
  },
  {
    label: 'Micronesia (Federated States of)',
    frenchShortName: 'Micronésie (États fédérés de)',
    code: 'FM',
    alpha3Code: 'FSM',
    numeric: 583,
  },
  {
    label: 'Moldova (the Republic of)',
    frenchShortName: 'Moldova (la République de)',
    code: 'MD',
    alpha3Code: 'MDA',
    numeric: 498,
  },
  {
    label: 'Monaco',
    frenchShortName: 'Monaco',
    code: 'MC',
    alpha3Code: 'MCO',
    numeric: 492,
  },
  {
    label: 'Mongolia',
    frenchShortName: 'Mongolie (la)',
    code: 'MN',
    alpha3Code: 'MNG',
    numeric: 496,
  },
  {
    label: 'Montenegro',
    frenchShortName: 'Monténégro (le)',
    code: 'ME',
    alpha3Code: 'MNE',
    numeric: 499,
  },
  {
    label: 'Montserrat',
    frenchShortName: 'Montserrat',
    code: 'MS',
    alpha3Code: 'MSR',
    numeric: 500,
  },
  {
    label: 'Morocco',
    frenchShortName: 'Maroc (le)',
    code: 'MA',
    alpha3Code: 'MAR',
    numeric: 504,
  },
  {
    label: 'Mozambique',
    frenchShortName: 'Mozambique (le)',
    code: 'MZ',
    alpha3Code: 'MOZ',
    numeric: 508,
  },
  {
    label: 'Myanmar',
    frenchShortName: 'Myanmar (le)',
    code: 'MM',
    alpha3Code: 'MMR',
    numeric: 104,
  },
  {
    label: 'Namibia',
    frenchShortName: 'Namibie (la)',
    code: 'NA',
    alpha3Code: 'NAM',
    numeric: 516,
  },
  {
    label: 'Nauru',
    frenchShortName: 'Nauru',
    code: 'NR',
    alpha3Code: 'NRU',
    numeric: 520,
  },
  {
    label: 'Nepal',
    frenchShortName: 'Népal (le)',
    code: 'NP',
    alpha3Code: 'NPL',
    numeric: 524,
  },
  {
    label: 'Netherlands (the)',
    frenchShortName: 'Pays-Bas (les)',
    code: 'NL',
    alpha3Code: 'NLD',
    numeric: 528,
  },
  {
    label: 'New Caledonia',
    frenchShortName: 'Nouvelle-Calédonie (la)',
    code: 'NC',
    alpha3Code: 'NCL',
    numeric: 540,
  },
  {
    label: 'New Zealand',
    frenchShortName: 'Nouvelle-Zélande (la)',
    code: 'NZ',
    alpha3Code: 'NZL',
    numeric: 554,
  },
  {
    label: 'Nicaragua',
    frenchShortName: 'Nicaragua (le)',
    code: 'NI',
    alpha3Code: 'NIC',
    numeric: 558,
  },
  {
    label: 'Niger (the)',
    frenchShortName: 'Niger (le)',
    code: 'NE',
    alpha3Code: 'NER',
    numeric: 562,
  },
  {
    label: 'Nigeria',
    frenchShortName: 'Nigéria (le)',
    code: 'NG',
    alpha3Code: 'NGA',
    numeric: 566,
  },
  {
    label: 'Niue',
    frenchShortName: 'Niue',
    code: 'NU',
    alpha3Code: 'NIU',
    numeric: 570,
  },
  {
    label: 'Norfolk Island',
    frenchShortName: "Norfolk (l'Île)",
    code: 'NF',
    alpha3Code: 'NFK',
    numeric: 574,
  },
  {
    label: 'North Macedonia',
    frenchShortName: 'Macédoine du Nord (la)',
    code: 'MK',
    alpha3Code: 'MKD',
    numeric: 807,
  },
  {
    label: 'Northern Mariana Islands (the)',
    frenchShortName: 'Mariannes du Nord (les Îles)',
    code: 'MP',
    alpha3Code: 'MNP',
    numeric: 580,
  },
  {
    label: 'Norway',
    frenchShortName: 'Norvège (la)',
    code: 'NO',
    alpha3Code: 'NOR',
    numeric: 578,
  },
  {
    label: 'Oman',
    frenchShortName: 'Oman',
    code: 'OM',
    alpha3Code: 'OMN',
    numeric: 512,
  },
  {
    label: 'Pakistan',
    frenchShortName: 'Pakistan (le)',
    code: 'PK',
    alpha3Code: 'PAK',
    numeric: 586,
  },
  {
    label: 'Palau',
    frenchShortName: 'Palaos (les)',
    code: 'PW',
    alpha3Code: 'PLW',
    numeric: 585,
  },
  {
    label: 'Palestine, State of',
    frenchShortName: 'Palestine, État de',
    code: 'PS',
    alpha3Code: 'PSE',
    numeric: 275,
  },
  {
    label: 'Panama',
    frenchShortName: 'Panama (le)',
    code: 'PA',
    alpha3Code: 'PAN',
    numeric: 591,
  },
  {
    label: 'Papua New Guinea',
    frenchShortName: 'Papouasie-Nouvelle-Guinée (la)',
    code: 'PG',
    alpha3Code: 'PNG',
    numeric: 598,
  },
  {
    label: 'Paraguay',
    frenchShortName: 'Paraguay (le)',
    code: 'PY',
    alpha3Code: 'PRY',
    numeric: 600,
  },
  {
    label: 'Peru',
    frenchShortName: 'Pérou (le)',
    code: 'PE',
    alpha3Code: 'PER',
    numeric: 604,
  },
  {
    label: 'Philippines (the)',
    frenchShortName: 'Philippines (les)',
    code: 'PH',
    alpha3Code: 'PHL',
    numeric: 608,
  },
  {
    label: 'Pitcairn',
    frenchShortName: 'Pitcairn',
    code: 'PN',
    alpha3Code: 'PCN',
    numeric: 612,
  },
  {
    label: 'Poland',
    frenchShortName: 'Pologne (la)',
    code: 'PL',
    alpha3Code: 'POL',
    numeric: 616,
  },
  {
    label: 'Portugal',
    frenchShortName: 'Portugal (le)',
    code: 'PT',
    alpha3Code: 'PRT',
    numeric: 620,
  },
  {
    label: 'Puerto Rico',
    frenchShortName: 'Porto Rico',
    code: 'PR',
    alpha3Code: 'PRI',
    numeric: 630,
  },
  {
    label: 'Qatar',
    frenchShortName: 'Qatar (le)',
    code: 'QA',
    alpha3Code: 'QAT',
    numeric: 634,
  },
  {
    label: 'Romania',
    frenchShortName: 'Roumanie (la)',
    code: 'RO',
    alpha3Code: 'ROU',
    numeric: 642,
  },
  {
    label: 'Russian Federation (the)',
    frenchShortName: 'Russie (la Fédération de)',
    code: 'RU',
    alpha3Code: 'RUS',
    numeric: 643,
  },
  {
    label: 'Rwanda',
    frenchShortName: 'Rwanda (le)',
    code: 'RW',
    alpha3Code: 'RWA',
    numeric: 646,
  },
  {
    label: 'Réunion',
    frenchShortName: 'Réunion (La)',
    code: 'RE',
    alpha3Code: 'REU',
    numeric: 638,
  },
  {
    label: 'Saint Barthélemy',
    frenchShortName: 'Saint-Barthélemy',
    code: 'BL',
    alpha3Code: 'BLM',
    numeric: 652,
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    frenchShortName: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    code: 'SH',
    alpha3Code: 'SHN',
    numeric: 654,
  },
  {
    label: 'Saint Kitts and Nevis',
    frenchShortName: 'Saint-Kitts-et-Nevis',
    code: 'KN',
    alpha3Code: 'KNA',
    numeric: 659,
  },
  {
    label: 'Saint Lucia',
    frenchShortName: 'Sainte-Lucie',
    code: 'LC',
    alpha3Code: 'LCA',
    numeric: 662,
  },
  {
    label: 'Saint Martin (French part)',
    frenchShortName: 'Saint-Martin (partie française)',
    code: 'MF',
    alpha3Code: 'MAF',
    numeric: 663,
  },
  {
    label: 'Saint Pierre and Miquelon',
    frenchShortName: 'Saint-Pierre-et-Miquelon',
    code: 'PM',
    alpha3Code: 'SPM',
    numeric: 666,
  },
  {
    label: 'Saint Vincent and the Grenadines',
    frenchShortName: 'Saint-Vincent-et-les Grenadines',
    code: 'VC',
    alpha3Code: 'VCT',
    numeric: 670,
  },
  {
    label: 'Samoa',
    frenchShortName: 'Samoa (le)',
    code: 'WS',
    alpha3Code: 'WSM',
    numeric: 882,
  },
  {
    label: 'San Marino',
    frenchShortName: 'Saint-Marin',
    code: 'SM',
    alpha3Code: 'SMR',
    numeric: 674,
  },
  {
    label: 'Sao Tome and Principe',
    frenchShortName: 'Sao Tomé-et-Principe',
    code: 'ST',
    alpha3Code: 'STP',
    numeric: 678,
  },
  {
    label: 'Saudi Arabia',
    frenchShortName: "Arabie saoudite (l')",
    code: 'SA',
    alpha3Code: 'SAU',
    numeric: 682,
  },
  {
    label: 'Senegal',
    frenchShortName: 'Sénégal (le)',
    code: 'SN',
    alpha3Code: 'SEN',
    numeric: 686,
  },
  {
    label: 'Serbia',
    frenchShortName: 'Serbie (la)',
    code: 'RS',
    alpha3Code: 'SRB',
    numeric: 688,
  },
  {
    label: 'Seychelles',
    frenchShortName: 'Seychelles (les)',
    code: 'SC',
    alpha3Code: 'SYC',
    numeric: 690,
  },
  {
    label: 'Sierra Leone',
    frenchShortName: 'Sierra Leone (la)',
    code: 'SL',
    alpha3Code: 'SLE',
    numeric: 694,
  },
  {
    label: 'Singapore',
    frenchShortName: 'Singapour',
    code: 'SG',
    alpha3Code: 'SGP',
    numeric: 702,
  },
  {
    label: 'Sint Maarten (Dutch part)',
    frenchShortName: 'Saint-Martin (partie néerlandaise)',
    code: 'SX',
    alpha3Code: 'SXM',
    numeric: 534,
  },
  {
    label: 'Slovakia',
    frenchShortName: 'Slovaquie (la)',
    code: 'SK',
    alpha3Code: 'SVK',
    numeric: 703,
  },
  {
    label: 'Slovenia',
    frenchShortName: 'Slovénie (la)',
    code: 'SI',
    alpha3Code: 'SVN',
    numeric: 705,
  },
  {
    label: 'Solomon Islands',
    frenchShortName: 'Salomon (les Îles)',
    code: 'SB',
    alpha3Code: 'SLB',
    numeric: 90,
  },
  {
    label: 'Somalia',
    frenchShortName: 'Somalie (la)',
    code: 'SO',
    alpha3Code: 'SOM',
    numeric: 706,
  },
  {
    label: 'South Africa',
    frenchShortName: "Afrique du Sud (l')",
    code: 'ZA',
    alpha3Code: 'ZAF',
    numeric: 710,
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    frenchShortName: 'Géorgie du Sud-et-les Îles Sandwich du Sud (la)',
    code: 'GS',
    alpha3Code: 'SGS',
    numeric: 239,
  },
  {
    label: 'South Sudan',
    frenchShortName: 'Soudan du Sud (le)',
    code: 'SS',
    alpha3Code: 'SSD',
    numeric: 728,
  },
  {
    label: 'Spain',
    frenchShortName: "Espagne (l')",
    code: 'ES',
    alpha3Code: 'ESP',
    numeric: 724,
  },
  {
    label: 'Sri Lanka',
    frenchShortName: 'Sri Lanka',
    code: 'LK',
    alpha3Code: 'LKA',
    numeric: 144,
  },
  {
    label: 'Sudan (the)',
    frenchShortName: 'Soudan (le)',
    code: 'SD',
    alpha3Code: 'SDN',
    numeric: 729,
  },
  {
    label: 'Suriname',
    frenchShortName: 'Suriname (le)',
    code: 'SR',
    alpha3Code: 'SUR',
    numeric: 740,
  },
  {
    label: 'Svalbard and Jan Mayen',
    frenchShortName: "Svalbard et l'Île Jan Mayen (le)",
    code: 'SJ',
    alpha3Code: 'SJM',
    numeric: 744,
  },
  {
    label: 'Sweden',
    frenchShortName: 'Suède (la)',
    code: 'SE',
    alpha3Code: 'SWE',
    numeric: 752,
  },
  {
    label: 'Switzerland',
    frenchShortName: 'Suisse (la)',
    code: 'CH',
    alpha3Code: 'CHE',
    numeric: 756,
  },
  {
    label: 'Syrian Arab Republic (the)',
    frenchShortName: 'République arabe syrienne (la)',
    code: 'SY',
    alpha3Code: 'SYR',
    numeric: 760,
  },
  {
    label: 'Taiwan (Province of China)',
    frenchShortName: 'Taïwan (Province de Chine)',
    code: 'TW',
    alpha3Code: 'TWN',
    numeric: 158,
  },
  {
    label: 'Tajikistan',
    frenchShortName: 'Tadjikistan (le)',
    code: 'TJ',
    alpha3Code: 'TJK',
    numeric: 762,
  },
  {
    label: 'Tanzania, the United Republic of',
    frenchShortName: 'Tanzanie (la République-Unie de)',
    code: 'TZ',
    alpha3Code: 'TZA',
    numeric: 834,
  },
  {
    label: 'Thailand',
    frenchShortName: 'Thaïlande (la)',
    code: 'TH',
    alpha3Code: 'THA',
    numeric: 764,
  },
  {
    label: 'Timor-Leste',
    frenchShortName: 'Timor-Leste (le)',
    code: 'TL',
    alpha3Code: 'TLS',
    numeric: 626,
  },
  {
    label: 'Togo',
    frenchShortName: 'Togo (le)',
    code: 'TG',
    alpha3Code: 'TGO',
    numeric: 768,
  },
  {
    label: 'Tokelau',
    frenchShortName: 'Tokelau (les)',
    code: 'TK',
    alpha3Code: 'TKL',
    numeric: 772,
  },
  {
    label: 'Tonga',
    frenchShortName: 'Tonga (les)',
    code: 'TO',
    alpha3Code: 'TON',
    numeric: 776,
  },
  {
    label: 'Trinidad and Tobago',
    frenchShortName: 'Trinité-et-Tobago (la)',
    code: 'TT',
    alpha3Code: 'TTO',
    numeric: 780,
  },
  {
    label: 'Tunisia',
    frenchShortName: 'Tunisie (la)',
    code: 'TN',
    alpha3Code: 'TUN',
    numeric: 788,
  },
  {
    label: 'Turkey',
    frenchShortName: 'Turquie (la)',
    code: 'TR',
    alpha3Code: 'TUR',
    numeric: 792,
  },
  {
    label: 'Turkmenistan',
    frenchShortName: 'Turkménistan (le)',
    code: 'TM',
    alpha3Code: 'TKM',
    numeric: 795,
  },
  {
    label: 'Turks and Caicos Islands (the)',
    frenchShortName: 'Turks-et-Caïcos (les Îles)',
    code: 'TC',
    alpha3Code: 'TCA',
    numeric: 796,
  },
  {
    label: 'Tuvalu',
    frenchShortName: 'Tuvalu (les)',
    code: 'TV',
    alpha3Code: 'TUV',
    numeric: 798,
  },
  {
    label: 'Uganda',
    frenchShortName: "Ouganda (l')",
    code: 'UG',
    alpha3Code: 'UGA',
    numeric: 800,
  },
  {
    label: 'Ukraine',
    frenchShortName: "Ukraine (l')",
    code: 'UA',
    alpha3Code: 'UKR',
    numeric: 804,
  },
  {
    label: 'United Arab Emirates (the)',
    frenchShortName: 'Émirats arabes unis (les)',
    code: 'AE',
    alpha3Code: 'ARE',
    numeric: 784,
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    frenchShortName: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    code: 'GB',
    alpha3Code: 'GBR',
    numeric: 826,
  },
  {
    label: 'United States Minor Outlying Islands (the)',
    frenchShortName: 'Îles mineures éloignées des États-Unis (les)',
    code: 'UM',
    alpha3Code: 'UMI',
    numeric: 581,
  },
  {
    label: 'United States of America (the)',
    frenchShortName: "États-Unis d'Amérique (les)",
    code: 'US',
    alpha3Code: 'USA',
    numeric: 840,
  },
  {
    label: 'Uruguay',
    frenchShortName: "Uruguay (l')",
    code: 'UY',
    alpha3Code: 'URY',
    numeric: 858,
  },
  {
    label: 'Uzbekistan',
    frenchShortName: "Ouzbékistan (l')",
    code: 'UZ',
    alpha3Code: 'UZB',
    numeric: 860,
  },
  {
    label: 'Vanuatu',
    frenchShortName: 'Vanuatu (le)',
    code: 'VU',
    alpha3Code: 'VUT',
    numeric: 548,
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    frenchShortName: 'Venezuela (République bolivarienne du)',
    code: 'VE',
    alpha3Code: 'VEN',
    numeric: 862,
  },
  {
    label: 'Viet Nam',
    frenchShortName: 'Viet Nam (le)',
    code: 'VN',
    alpha3Code: 'VNM',
    numeric: 704,
  },
  {
    label: 'Virgin Islands (British)',
    frenchShortName: 'Vierges britanniques (les Îles)',
    code: 'VG',
    alpha3Code: 'VGB',
    numeric: 92,
  },
  {
    label: 'Virgin Islands (U.S.)',
    frenchShortName: 'Vierges des États-Unis (les Îles)',
    code: 'VI',
    alpha3Code: 'VIR',
    numeric: 850,
  },
  {
    label: 'Wallis and Futuna',
    frenchShortName: 'Wallis-et-Futuna',
    code: 'WF',
    alpha3Code: 'WLF',
    numeric: 876,
  },
  {
    label: 'Western Sahara*',
    frenchShortName: 'Sahara occidental (le)*',
    code: 'EH',
    alpha3Code: 'ESH',
    numeric: 732,
  },
  {
    label: 'Yemen',
    frenchShortName: 'Yémen (le)',
    code: 'YE',
    alpha3Code: 'YEM',
    numeric: 887,
  },
  {
    label: 'Zambia',
    frenchShortName: 'Zambie (la)',
    code: 'ZM',
    alpha3Code: 'ZMB',
    numeric: 894,
  },
  {
    label: 'Zimbabwe',
    frenchShortName: 'Zimbabwe (le)',
    code: 'ZW',
    alpha3Code: 'ZWE',
    numeric: 716,
  },
  {
    label: 'Åland Islands',
    frenchShortName: 'Åland(les Îles)',
    code: 'AX',
    alpha3Code: 'ALA',
    numeric: 248,
  },
  {
    label: 'European Union',
    frenchShortName: 'European Union',
    code: 'EU',
    alpha3Code: 'EU',
    numeric: 248,
  },
];

export const _basicQA = [
  {
    value: 'accept_deposits',
    isSelected: false,
    label: 'Do you accept deposits?',
  },
  {
    value: 'has_digital_lending_platform',
    isSelected: false,
    label: 'Are you using a digital platform for lending?',
  },
  {
    value: 'has_digital_lending_agreements',
    isSelected: false,
    label:
      'Do you have arrangements with Lending service providers or other regulated entities for digital lending?',
  },
  {
    value: 'funds_withholding',
    isSelected: false,
    label: 'Do you hold the money before the settlement?',
  },
  {
    value: 'issues_credit_cards',
    isSelected: false,
    label: 'Do you issue branded or co-branded credit cards?',
  },
];

export const _businessType = [
  'Digital Payments',
  'Consumer Landing',
  'Business Landing',
  'Payment Gateway',
  'Cross-Border Payments',
  'E-Money',
  'Card Issuance',
  'Insurance',
  'Deposits',
];
export const paymentEntities = [
  { value: 'Payment_Bank', label: 'Payment Bank' },
  { value: 'Prepaid_Payment_Instrument_Issuer', label: 'Prepaid Payment Instrument Issuer' },
  { value: 'Payment_Aggregator', label: 'Payment Aggregator' },
  { value: 'Payment_Gateway', label: 'Payment Gateway' },
  { value: 'Payment_Service_Provider_IFSCA', label: 'Payment Service Provider (IFSCA)' },
  { value: 'E_money_EMI', label: 'E-money (EMI)' },
  { value: 'Payment_Institution', label: 'Payment Institution' },
];
export const _licencesAndCountry = [
  {
    key: 'payment_bank',
    value: false,
    label: 'Payment Bank',
  },
  {
    key: 'prepaid_payment_instrument_issuer',
    value: false,
    label: 'Prepaid Payment Instrument Issuer',
  },
  {
    key: 'payment_aggregator',
    value: false,
    label: 'Payment Aggregator',
  },
  {
    key: 'payment_gateway',
    value: false,
    label: 'Payment Gateway',
  },
  {
    key: 'payment_service_provider_ifsca',
    value: false,
    label: 'Payment Service Provider (IFSCA)',
  },
  {
    key: 'e_money_emi',
    value: false,
    label: 'E-money (EMI)',
  },
  {
    key: 'payment_institution',
    value: false,
    label: 'Payment Institution',
  },
];

export function getQA(key) {
  const QA = _basicQA.find((val) => val.value === key);
  return {
    key,
    value: true,
    label: QA.label,
  };
}
export function getCountryCodeByName(name) {
  const countryTemp = countriesAlpha3.find((country) => country.label === name);
  return countryTemp?.alpha3Code || '';
}
export function getPaymentEntitiesLabel(key) {
  const paymentEnrty = paymentEntities.find((val) => val.value === key);
  return paymentEnrty.label;
}

export const FILE_TYPE_OPTIONS = [['folder'], ['txt'], ['zip']];

export const ALERT_TYPE = ['type1', 'type2', 'type3'];
export const DOCS_TYPE = ['NEWS', 'BLOG'];
export const SOURCE_TYPE = ['FCA', 'EBA', 'BCA'];
export const TOPICS_TYPE = ['FCA', 'EBA', 'BCA'];
export const TABS_FILTER_TYPE = ['All Alerts', 'Agency Updates', 'Regulation changes', 'Starred'];
export const ALERT_FLITER_LABEL = ['Alert Type', 'Docs Type', 'Sources', 'Topics'];
export const ALERTS_TYPE_OPTIONS = [ALERT_TYPE, DOCS_TYPE, SOURCE_TYPE, TOPICS_TYPE];
export const TABS = TABS_FILTER_TYPE.map((val) => ({ value: val, label: val }));
export const GAP_ANALYSIS_FLITER_LABEL = ['Risk Level', 'Impacted Area', 'Owner', 'Task Status'];
export const IMPACT_FLITER_LABEL = ['All', 'Product Impacted', 'Business Impacted'];
