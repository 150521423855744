import { useRef, useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname, useSearchParams } from 'src/routes/hooks';

import { CONFIG } from 'src/config-global';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export function AuthGuard({ children }) {
  const router = useRouter();
  const isAuthRef = useRef(false);

  const pathname = usePathname();

  const searchParams = useSearchParams();

  const { authenticated, loading, userData } = useAuthContext();

  const [isChecking, setIsChecking] = useState(true);

  const createQueryString = useCallback(
    (name, value) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const checkPermissions = async () => {
    if (loading) {
      return;
    }

    if (!authenticated) {
      const { method } = CONFIG.auth;

      const signInPath = {
        firebase: paths.auth.firebase.signIn,
      }[method];

      const href = `${signInPath}?${createQueryString('returnTo', pathname)}`;

      router.replace(href);
      return;
    }
    if (authenticated && !isAuthRef.current) {
      // const data = await getUserData(user.id);
      if (!userData?.organization?.profile) {
        router.push(paths.dashboard.root); // for now it's redirecting to dashboard
        setIsChecking(false);
        isAuthRef.current = true;
        return;
      }
    }
    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, loading, userData]);

  if (isChecking) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
