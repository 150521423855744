import { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { useDropzone } from 'react-dropzone';
import { varAlpha } from 'src/theme/styles';
import { Iconify } from 'src/components/iconify';
import { UploadPlaceholder } from 'src/components/upload/components/placeholder';
import { RejectionFiles } from 'src/components/upload/components/rejection-files';
import {
  SingleFilePreview,
  DeleteButton,
} from 'src/components/upload/components/preview-single-file';
import { MultiFilePreview } from 'src/components/upload';

export default function UploadFile({ onFileChange, multiple }) {
  const [file, setFile] = useState(null);

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
      //   'application/msword': ['.doc'],
      //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      //   'application/vnd.ms-excel': ['.xls'],
      //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      //   'text/plain': ['.txt']
    },
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setFile(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
        // if (onFileChange) {
        onFileChange(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
        // }
      }
    },
  });

  const handleRemove = () => {
    setFile(null);
    if (onFileChange) {
      onFileChange(null);
    }
  };

  const hasError = isDragReject;
  const hasFile = !!file;

  return (
    <Box sx={{ width: 1, position: 'relative' }}>
      {!hasFile && (
        <Box
          {...getRootProps()}
          sx={{
            p: 5,
            outline: 'none',
            borderRadius: 1,
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.08),
            border: (theme) => `1px dashed ${varAlpha(theme.vars.palette.grey['500Channel'], 0.2)}`,
            transition: (theme) => theme.transitions.create(['opacity', 'padding']),
            '&:hover': { opacity: 0.72 },
            ...(isDragActive && { opacity: 0.72 }),
            ...(hasError && {
              color: 'error.main',
              borderColor: 'error.main',
              bgcolor: (theme) => varAlpha(theme.vars.palette.error.mainChannel, 0.08),
            }),
          }}
        >
          <input {...getInputProps()} />
          <UploadPlaceholder />
        </Box>
      )}

      {hasFile && (
        <Box sx={{}}>
          {/* <SingleFilePreview file={file} /> */}
          <MultiFilePreview files={[file]} onRemove={handleRemove} sx={{ my: 0 }} />

          {/* <DeleteButton onClick={handleRemove} /> */}
        </Box>
      )}

      {!hasFile && <FormHelperText sx={{ px: 2, mt: 1 }}>Allowed files: PDF</FormHelperText>}

      <RejectionFiles files={fileRejections} />
    </Box>
  );
}
