// ----------------------------------------------------------------------

import { countriesAlpha3 } from 'src/_mock/_map/countries';

export function getCountry(inputValue) {
  const option = countriesAlpha3.filter(
    (country) => country.label === inputValue || country.code === inputValue
  )[0];

  return { code: option?.code, label: option?.label, phone: option?.phone };
}

// ----------------------------------------------------------------------

export function displayValueByCountryCode(inputValue) {
  const option = countriesAlpha3.filter((country) => country.code === inputValue)[0];

  return option.label;
}
