import { Box, Card, Skeleton, Stack } from '@mui/material';

export default function CardShimmerEffect({ sx, lineOnly = true }) {
  return lineOnly ? (
    <Card sx={{ px: 4, borderRadius: 1, ...sx }}>
      <Stack spacing={2} sx={{ py: 3 }}>
        {/* Executive summary */}
        <Skeleton variant="text" width="90%" height={24} />
        <Skeleton variant="text" width="85%" height={24} />
        <Skeleton variant="text" width="88%" height={24} />

        {/* Timeline content */}
        <Stack spacing={2} sx={{ mt: 3 }}>
          <Skeleton variant="rectangular" width="100%" height={100} />
          <Skeleton variant="rectangular" width="100%" height={80} />
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Stack>
      </Stack>
    </Card>
  ) : (
    <Stack spacing={2} sx={{ m: 3 }}>
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
    </Stack>
  );
}
