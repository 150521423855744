import { useState } from 'react';
import { IconButton, MenuList, MenuItem, Divider, Box } from '@mui/material';
import { CustomPopover } from 'src/components/custom-popover';
import { CONFIG } from 'src/config-global';
import { Iconify } from 'src/components/iconify';

export default function CommonShareBtn({ handleSelection, customMenuItems = false }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const menuItems = customMenuItems
    ? [
        {
          key: 'excel',
          icon: {
            type: 'image',
            src: `${CONFIG.site.imageBasePath}/assets/icons/files/ic-excel.svg`,
          },
          label: 'Download Excel',
          disabled: true,
        },
        {
          key: 'history',

          icon: { type: 'iconify', name: 'solar:history-outline' },
          //solar:history-outline

          label: 'View History',
          disabled: true,
        },
      ]
    : [
        {
          key: 'calendar',
          icon: { type: 'iconify', name: 'solar:calendar-date-bold' },
          label: 'Add to calender',
          disabled: true,
        },
        { key: 'divider-1', type: 'divider' },
        {
          key: 'pdf',
          icon: {
            type: 'image',
            src: `${CONFIG.site.basePath}/assets/icons/files/ic-pdf.svg`,
          },
          label: 'Download PDF',
          disabled: true,
        },
        {
          key: 'excel',
          icon: {
            type: 'image',
            src: `${CONFIG.site.imageBasePath}/assets/icons/files/ic-excel.svg`,
          },
          label: 'Download Excel',
          disabled: true,
        },
        { key: 'divider-2', type: 'divider' },
        {
          key: 'asana',
          icon: {
            type: 'image',
            src: `${CONFIG.site.basePath}/assets/icons/app/ic-asana.svg`,
          },
          label: 'Export to Asana',
          disabled: true,
        },
        {
          key: 'grc',
          icon: {
            type: 'image',
            src: `${CONFIG.site.basePath}/assets/icons/app/ic-app-onedrive.svg`,
          },
          label: 'Integrate with GRC',
          disabled: true,
        },
      ];

  return (
    <>
      <IconButton color={open ? 'inherit' : 'default'} onClick={handleClick}>
        <Iconify size={24} icon="eva:more-horizontal-fill" />
      </IconButton>

      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          {menuItems.map((item, index) =>
            item.type === 'divider' ? (
              <Divider key={`divider-${index}`} sx={{ borderStyle: 'dashed' }} />
            ) : (
              <MenuItem
                key={item.label}
                disabled={item.disabled}
                onClick={() => {
                  handleSelection(item);
                  handleClose();
                }}
              >
                {item.icon.type === 'iconify' ? (
                  <Iconify icon={item.icon.name} />
                ) : (
                  <Box component="img" src={item.icon.src} sx={{ width: 24, height: 24 }} />
                )}
                {item.label}
              </MenuItem>
            )
          )}
        </MenuList>
      </CustomPopover>
    </>
  );
}
