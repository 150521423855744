import {
  signOut as _signOut,
  signInWithPopup as _signInWithPopup,
  GoogleAuthProvider as _GoogleAuthProvider,
  GithubAuthProvider as _GithubAuthProvider,
  TwitterAuthProvider as _TwitterAuthProvider,
  confirmPasswordReset as _confirmPasswordReset,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  // createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
} from 'firebase/auth';

import { AUTH } from 'src/lib/firebase';

/** **************************************
 * Sign in
 *************************************** */
export const signInWithPassword = async ({ email, password }) => {
  try {
    await _signInWithEmailAndPassword(AUTH, email, password);

    // const user = AUTH.currentUser;

    // if (!user?.emailVerified) {
    //   throw new Error('Email not verified!');
    // }
  } catch (error) {
    throw error;
  }
};

export const signInWithGoogle = async () => {
  const provider = new _GoogleAuthProvider();
  await _signInWithPopup(AUTH, provider);
};

export const signInWithGithub = async () => {
  const provider = new _GithubAuthProvider();
  await _signInWithPopup(AUTH, provider);
};

export const signInWithTwitter = async () => {
  const provider = new _TwitterAuthProvider();
  await _signInWithPopup(AUTH, provider);
};

/** **************************************
 * Sign up
 *************************************** */
export const signUp = async ({ email, password, firstName, lastName }) => {
  try {
    // const newUser = await _createUserWithEmailAndPassword(AUTH, email, password);
    /*
     * (1) If skip emailVerified
     * Remove : await _sendEmailVerification(newUser.user);
     */
    // await _sendEmailVerification(newUser.user);
    // const userProfile = doc(collection(FIRESTORE, 'users'), newUser.user?.uid);
    // await setDoc(userProfile, {
    //   uid: newUser.user?.uid,
    //   email,
    //   displayName: `${firstName} ${lastName}`,
    // });
  } catch (error) {
    throw error;
  }
};

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async () => {
  await _signOut(AUTH);
};

/** **************************************
 * Reset password
 *************************************** */
export const sendPasswordResetEmail = async ({ email }) => {
  const actionCodeSettings = {
    url: `${window.location.origin}`,
    handleCodeInApp: true,
  };

  await _sendPasswordResetEmail(AUTH, email, actionCodeSettings);
};
export const confirmPasswordReset = async ({ oobCode, newPassword }) => {
  await _confirmPasswordReset(AUTH, oobCode, newPassword);
};

export const getIdTokenResult = async () => {
  const user = AUTH?.currentUser;
  const idTokenResult = await user.getIdTokenResult();
  return idTokenResult;
};
// https://copilot.zango.ai/auth/firebase/change-password/?email=%EMAIL%
