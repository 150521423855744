import Alert from '@mui/material/Alert';

import { useBoolean } from 'src/hooks/use-boolean';

import { CONFIG } from 'src/config-global';

import { Section } from './section';
import { Main, Content } from './main';
import { HeaderBase } from '../core/header-base';
import { LayoutSection } from '../core/layout-section';
import { usePathname } from 'src/routes/hooks';
import { isNovaUser } from 'src/utils/user-type-check';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export function AuthSplitLayout({ sx, isOnBoarding, section, children }) {
  const mobileNavOpen = useBoolean();
  const pathname = usePathname();
  const layoutQuery = 'md';
  const isNova = isNovaUser();
  const isLogin =
    pathname.includes(paths.auth.firebase.signIn) ||
    pathname.includes(paths.auth.firebase.verify) ||
    pathname.includes(paths.auth.firebase.resetPassword) ||
    pathname.includes(paths.auth.firebase.changePassword);
  return (
    <LayoutSection
      headerSection={
        /** **************************************
         * Header
         *************************************** */
        isNova && (
          <HeaderBase
            disableElevation
            layoutQuery={layoutQuery}
            onOpenNav={mobileNavOpen.onTrue}
            slotsDisplay={{
              signIn: false,
              account: false,
              purchase: false,
              contacts: false,
              searchbar: false,
              workspaces: false,
              menuButton: false,
              localization: false,
              notifications: false,
              isLogedin: false,
              isNovaUser: isNova && isLogin,
            }}
            slots={{
              topArea: (
                <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                  This is an info Alert.
                </Alert>
              ),
            }}
            slotProps={{ container: { maxWidth: false } }}
            sx={{ position: { [layoutQuery]: 'fixed' } }}
          />
        )
      }
      /** **************************************
       * Footer
       *************************************** */
      footerSection={null}
      /** **************************************
       * Style
       *************************************** */
      sx={sx}
      cssVars={{
        '--layout-auth-content-width': isOnBoarding ? '820px' : '420px',
      }}
    >
      <Main layoutQuery={layoutQuery}>
        {/* <Section
          title={section?.title}
          layoutQuery={layoutQuery}
          imgUrl={
            !isOnBoarding
              ? section?.imgUrl
              : `${CONFIG.site.imageBasePath}/assets/illustrations/illustration-seo.svg`
          }
          method={CONFIG.auth.method}
          subtitle={section?.subtitle}
        /> */}
        <Content layoutQuery={layoutQuery}>{children}</Content>
      </Main>
    </LayoutSection>
  );
}
