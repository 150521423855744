import 'src/global.css';

// ----------------------------------------------------------------------

import { Router } from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import { ThemeProvider } from 'src/theme/theme-provider';

import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from 'src/auth/context/firebase';
import { Snackbar } from './components/snackbar';
import { LocalizationProvider } from './locales';
import { I18nProvider } from 'src/locales/i18n-provider';

import ErrorBoundary from './components/error-boundary';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();
  if (!localStorage.getItem('clean')) {
    localStorage.clear();
    localStorage.setItem('clean', 'cleaned');
  }

  useEffect(() => {
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  });
  return (
    <ErrorBoundary>
      <I18nProvider>
        <LocalizationProvider>
          <AuthProvider>
            <SettingsProvider settings={defaultSettings}>
              <ThemeProvider>
                <MotionLazy>
                  <Snackbar />
                  <ProgressBar />
                  <SettingsDrawer />
                  <Router />
                </MotionLazy>
              </ThemeProvider>
            </SettingsProvider>
          </AuthProvider>
        </LocalizationProvider>
      </I18nProvider>
    </ErrorBoundary>
  );
}
