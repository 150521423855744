import { useEffect, useState, useRef } from 'react';
import { Badge, Button, Checkbox, Tooltip, Popover } from '@mui/material';
import { Iconify } from 'src/components/iconify';
import CommonSearch from '../../core/comman-search';
import { toast } from 'sonner';
import axiosInstance from 'src/utils/axios';
import useLawLibraryStore from 'src/zustand-store/law-library-store/law-library-store';
import { useBoolean } from 'src/hooks/use-boolean';
import CommonShareBtn from '../comman-share-btn';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import UploadLegislation from 'src/sections/components/upload-legislation';
import PolicyStatusDropdown from 'src/sections/components/policy-status-dropdown';
import { CustomPopover } from 'src/components/custom-popover';
import { POLICY_STATUS_OPTIONS } from 'src/constant/filters';

export default function LawLibraryHeader({ currentNav, backButton, isInternalPolicies = false }) {
  const router = useRouter();
  const { dataSummary, updateFilters, filters, data } = useLawLibraryStore();

  const stared = useBoolean(dataSummary?.star || false);
  const [searchValue, setSearchValue] = useState(filters?.name || '');
  const iconRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const pendingData = data?.filter((item) => item.status === POLICY_STATUS_OPTIONS[0]) || [];

  useEffect(() => {
    stared.setValue(dataSummary?.star || false);
    setSearchValue(filters?.name || '');
  }, [dataSummary, filters?.name]);

  const handleChange = async () => {
    const isChecked = !stared.value;
    stared.onToggle();

    try {
      const response = await axiosInstance({
        method: isChecked ? 'put' : 'delete',
        url: `policies/${dataSummary.id}/star`,
      });

      if (response.status === 200) {
        toast.success(response.data?.message || 'Star status updated successfully');
      } else {
        stared.onToggle();
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      stared.onToggle();
      toast.error(error.response?.data?.message || 'Failed to update star status');
    }
  };

  const openPDF = () => router.push(`${paths.dashboard.lawLibrary}/pdf/${dataSummary.id}`);
  const handleSearch = (value) => updateFilters({ name: value });
  const handleFileUpload = () => {};
  const handleSelection = () => {};
  const handleOpenPopover = () => setOpenPopover(true);
  const handleClosePopover = () => setOpenPopover(false);

  const buttonStyles = {
    p: 1,
    gap: 0,
    borderRadius: 1,
    width: 34,
    height: 36,
    minWidth: 36,
  };

  return (
    <>
      {!backButton && (
        <>
          <CommonSearch handleSearch={handleSearch} initialValue={searchValue} />
          <UploadLegislation onFileUpload={handleFileUpload} />
          <Tooltip title="Recently uploaded documents">
            {pendingData && pendingData?.length > 0 ? (
              <Badge
                color="secondary"
                ref={iconRef}
                badgeContent={pendingData.length}
                sx={{
                  '& .MuiBadge-badge': {
                    right: -3,
                    top: 3,
                  },
                }}
                onClick={handleOpenPopover}
              >
                <Button variant="soft" color="primary" size="small" sx={buttonStyles}>
                  <Iconify
                    icon="solar:upload-square-outline"
                    sx={{ width: '20px', height: '20px' }}
                  />
                </Button>
              </Badge>
            ) : (
              <Iconify
                ref={iconRef}
                sx={{ ml: 1, cursor: 'pointer', width: '20px', height: '20px' }}
                icon="solar:upload-square-outline"
                onClick={handleOpenPopover}
              />
            )}
          </Tooltip>
          {openPopover && iconRef.current && (
            <CustomPopover
              open={openPopover}
              anchorEl={iconRef.current}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              slotProps={{ paper: { sx: { p: 0 } } }}
            >
              <PolicyStatusDropdown
                list={data}
                handleClosePopover={handleClosePopover}
                isInternalPolicies={isInternalPolicies}
              />
            </CustomPopover>
          )}
        </>
      )}
      {backButton && !currentNav?.pathname.includes('pdf') && (
        <>
          {' '}
          <Tooltip title={stared.value ? 'Remove from starred' : 'Add to starred'}>
            <Checkbox
              color="warning"
              key="star"
              icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
              checkedIcon={<Iconify icon="eva:star-fill" />}
              checked={stared.value}
              onChange={handleChange}
              sx={{ p: 0.75 }}
            />
          </Tooltip>
          <Button
            disableRipple
            color="inherit"
            onClick={openPDF}
            startIcon={<Iconify icon="eva:file-text-outline" />}
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              py: 1,
              px: 2,
              backgroundColor: 'rgba(145, 158, 171, 0.08)',
              fontFamily: 'Inter',
              fontSize: 'var(--componentsbuttonsmallsize)',
              fontWeight: 500,
              lineHeight: 'var(--componentsbuttonsmallline-height)',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              '&:hover': {
                backgroundColor: 'rgba(145, 158, 171, 0.16)',
              },
            }}
          >
            View Legislation Doc
          </Button>
        </>
      )}
      <CommonShareBtn handleSelection={handleSelection} customMenuItems={true} />
    </>
  );
}
