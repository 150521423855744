import { USER_TYPE } from 'src/config-global';

export const isNovaUser = () => {
  const user = getUser();
  return user?.isNova;
};
export const getUser = () => {
  const isNova = window.origin.includes('nb.dev.zango.ai') || window.origin.includes('nb.zango.ai');
  return {
    name: isNova ? USER_TYPE[0] : null,
    isNova,
  };
};
