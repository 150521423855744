import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ROOTS } from '../paths';

// ----------------------------------------------------------------------

const OnBoardingPage = lazy(() =>
  import('src/sections/on-boarding/view/on-boading-view').then((module) => ({
    default: module.OnBoardingView,
  }))
);

// ----------------------------------------------------------------------

export const onBoardingRoutes = [
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [{ path: ROOTS.ONBOARDING, element: <OnBoardingPage /> }],
  },
];
