import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  errorRetryCount: 0,
  shouldRetryOnError: true,
};

// ----------------------------------------------------------------------

export function useGetPolices() {
  const url = endpoints.polices.policesList;
  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);
  const memoizedValue = useMemo(
    () => ({
      polices: data,
      policesLoading: isLoading,
      policesError: error,
      policesValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
export function useGetSlackMembers(isMultiLang = false) {
  const url = '/slack/members';

  const { data, error, isValidating } = useSWR(
    !isMultiLang ? url : null,
    async () => {
      const response = await axiosInstance.get(url);
      if (response.status !== 200) {
        throw new Error('Failed to fetch Slack users');
      }
      return response.data;
    },
    swrOptions
  );

  const memoizedValue = useMemo(
    () => ({
      slackMembers: !isMultiLang ? data : [],
      slackMembersLoading: !isMultiLang ? !error && !data : false,
      slackMembersError: error,
      slackMembersValidating: isValidating,
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

export function useGetSlackEmojis(isMultiLang = false) {
  const url = '/slack/emojis';

  const { data, error, isValidating } = useSWR(
    !isMultiLang ? url : null,
    async () => {
      const response = await axiosInstance.get(url);
      if (response.status !== 200) {
        throw new Error('Failed to fetch Slack emojis');
      }
      const { emoji } = response.data;
      const custom = [
        {
          id: 'slack',
          name: 'Slack',
          emojis: Object.entries(emoji)
            .filter(([, value]) => value.startsWith('http'))
            .map(([key, value]) => ({
              id: key,
              name: key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
              keywords: [key],
              skins: [{ src: value }],
            })),
        },
      ];
      return custom;
    },
    swrOptions
  );

  const memoizedValue = useMemo(
    () => ({
      slackEmojis: data,
      slackEmojisLoading: !isMultiLang ? !error && !data : false,
      slackEmojisError: error,
      slackEmojisValidating: isValidating,
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetPoliceById(policy_id) {
  const url = policy_id ? [endpoints.polices.root, { params: { policy_id } }] : '';

  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);

  const memoizedValue = useMemo(
    () => ({
      police: data,
      policeLoading: isLoading,
      policeError: error,
      policeValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetRulePoliceById(policy_id) {
  const url = policy_id ? [endpoints.rules.root, { params: { policy_id } }] : '';

  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);

  const memoizedValue = useMemo(
    () => ({
      rules: data,
      rulesLoading: isLoading,
      rulesError: error,
      rulesValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
// export function useGetUserById(userId) {
//   const url = `${endpoints.user}/${userId}`;

//   const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);

//   const memoizedValue = useMemo(
//     () => ({
//       userDetails: data,
//       userLoading: isLoading,
//       userError: error,
//       userValidating: isValidating,
//     }),
//     [data, error, isLoading, isValidating]
//   );
//   return memoizedValue;
// }
export async function getUserData(userId) {
  try {
    const response = await axiosInstance.get(`users/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function updateUserData(userId, userData) {
  try {
    const response = await axiosInstance.patch(`users/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function useGetUserData(userId) {
  const url = `${endpoints.user}/${userId}`;
  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);
  const memoizedValue = useMemo(
    () => ({
      user: data,
      userIsLoading: isLoading,
      userError: error,
      userValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}

export function useGetSummaryListById(summaryId) {
  const url = `${endpoints.alerts.root}/${summaryId}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(url, fetcher, swrOptions);

  const memoizedValue = useMemo(
    () => ({
      alert: data,
      alertLoading: isLoading,
      alertError: error,
      alertValidating: isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSummaryList() {
  const url = endpoints.alerts.root;

  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      isValidating,
    }),
    [data, isLoading, error, isValidating]
  );

  return memoizedValue;
}

export function useGetUserList() {
  const url = endpoints.user;

  const { data, isLoading, error, isValidating } = useSWR(url, fetcher, swrOptions);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      isValidating,
    }),
    [data, isLoading, error, isValidating]
  );

  return memoizedValue;
}

export async function getSummaryList() {
  try {
    // const url = endpoints.alerts.root;

    const response = await axiosInstance.get(`${endpoints.alerts.root}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const policeData = [
  {
    reference_docs: null,
    description: "UK Information Commissioner's Office",
    url: '/policies/01900996-3980-7f33-8bd9-506aec02a5d4/assets/ICO-Cookie-Guidance.pdf',
    id: '01900996-3980-7f33-8bd9-506aec02a5d4',
    created_at: '2024-06-11T23:15:58.900412',
    updated_at: '2024-06-11T23:18:54.623375',
    docs: ['01900996-a013-7686-a3d0-7673030fc1a9'],
    name: 'ICO Cookie Guidance',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'The Consumer Contracts Regulation 2013',
    url: '/policies/019009a3-bcc2-727f-8da0-83593246d025/assets/The Consumer Contracts Regulation UK.pdf',
    id: '019009a3-bcc2-727f-8da0-83593246d025',
    created_at: '2024-06-11T23:30:44.443642',
    updated_at: '2024-06-12T00:10:21.443724',
    docs: ['019009a4-43d3-7039-a97b-f37aac1c2a9e'],
    name: 'Consumer Protection',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: ['019009a4-43d3-7039-a97b-f37aac1c2a9e'],
    description: 'Zara UK',
    url: '/policies/019009ca-958d-7c0c-a3b0-9d9f895e005b/assets/Zara UK Online store policy.pdf',
    id: '019009ca-958d-7c0c-a3b0-9d9f895e005b',
    created_at: '2024-06-12T00:13:10.319417',
    updated_at: '2024-06-12T00:47:25.627945',
    docs: ['019009ca-e9af-7e23-aa9f-66a165495f3b'],
    name: 'Online shop policy',
    extra: {
      crossmap: {
        doc_to_ref: {
          '019009ca-e9af-7e23-aa9f-66a165495f3b_1_5': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_1',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_74',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_22',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_2_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_76',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_2_7': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_104',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_3_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_4_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_6',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_50',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_96',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_4_8': ['019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57'],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_3': ['019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57'],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_5': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_25',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_29_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_4_21',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_6': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_8_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_19',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_34_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_22',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_46',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_9': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_12_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_74',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_13_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_76',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_50',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_25',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_20',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_13_8': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_4',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_14_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_40',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_4_21',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_5_22',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_5_6',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_8',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_84',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_1_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_25',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_16_5': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_82',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_2_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_3': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_53',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_1_17': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_50',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_6',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_96',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_4_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_1_21': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_35',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_1_32': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_1_39': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_33',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_64',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_2_16': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_76',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_4_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_19',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_2_33': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_72',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_96',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_2_40': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_40',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_72',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_3_10': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_3_16': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_3_27': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_18',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_3_31': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_19',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_3_42': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_4_11': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_96',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_72',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_4_27': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_96',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_104',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_5_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_34',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_12',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_72',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_18',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_4_34': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_22',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_11': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_17': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_22': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_32': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_37': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_5_43': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_11': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_7',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_15': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_25',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_20',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_46',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_21': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_35',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_26': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_31': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_33': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_46',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_20',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_7',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_36': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_40',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_45',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_32',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_6_49': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_45',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_35',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_10': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_20': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_26': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_37': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_7_46': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_8_29': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_8_43': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_8_50': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_14': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_19',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_20': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_29': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_34': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_19',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_49',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_41': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_9_50': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_46',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_4',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_20',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_25',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_58',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_10_12': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_75',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_67',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_10_24': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_23',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_10': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_19': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_112',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_60',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_15',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_29': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_36': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_96',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_104',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_112',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_31_18',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_29',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_72',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_57',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_40': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_11_44': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_12_17': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_57',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_74',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_77',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_47',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_12_24': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_12_41': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_12_48': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_76',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_31',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_13_18': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_25',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_51',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_13_37': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_14_19': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_40',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_94',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_4',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_50',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_14_29': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_50',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_19_6',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_53',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_14_36': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_50',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_14_43': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_10',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_29_13',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_28_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_28_59',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_25',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_16_23': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_4',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_40',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_42',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_88',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_16_31': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_4',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_88',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_46',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_16_56': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_11',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_33_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_34_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_56',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_15_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_10_4',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_26_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_65',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_13_47',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_16_30',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_20',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_25',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_17_43',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_36',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_41',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_46',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_16_81': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_2_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_12_2',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_1_10',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_17_14': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_7_35',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_17_24': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_14_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_30_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_27_14',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_17_40': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_17_47': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_13': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_2_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_4_21',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_21': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_23_74',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_22_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_40',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_32': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_40',
          ],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_37': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_39': [],
          '019009ca-e9af-7e23-aa9f-66a165495f3b_18_46': [
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_21_28',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_38',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_35',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_9_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_26',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_8_53',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_6_0',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_4_21',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_3_7',
            '019009a4-43d3-7039-a97b-f37aac1c2a9e_2_0',
          ],
        },
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'The Consumer Contracts Regulations 2013',
    url: '/policies/01903ab7-c058-78ef-849f-64e3bceb6527/assets/The Consumer Contracts Regulation UK.pdf',
    id: '01903ab7-c058-78ef-849f-64e3bceb6527',
    created_at: '2024-06-21T12:13:59.696379',
    updated_at: '2024-06-27T19:02:05.646160',
    docs: ['01903ab8-378c-741a-b85c-269628466128'],
    name: 'The Consumer Contracts Regulations',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: true,
  },
  {
    reference_docs: null,
    description: 'UK AML 2017 V1',
    url: '/policies/01903964-f6b5-76fd-a17c-2ef73b5f9f1c/assets/AML 2017 copy.pdf',
    id: '01903964-f6b5-76fd-a17c-2ef73b5f9f1c',
    created_at: '2024-06-21T06:03:57.012077',
    updated_at: '2024-06-21T08:38:04.364448',
    docs: ['01903966-0e09-7610-b40e-766f89fa81dd'],
    name: 'UK AML 2017 V1',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'Ministry of Justice UK. Zango Library v0.01.',
    url: '/policies/01903346-5bbe-7d20-b9c9-6705ca85f35b/assets/bribery-act-2010-guidance.pdf',
    id: '01903346-5bbe-7d20-b9c9-6705ca85f35b',
    created_at: '2024-06-20T01:32:47.854112',
    updated_at: '2024-06-20T02:01:15.407861',
    docs: ['01903346-d2c5-7e6f-8e4b-06ef8e4b236d'],
    name: 'Bribery Act Guidance',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'Bribery Act 2010, UK. Zango Library v0.01.',
    url: '/policies/01903334-3553-70e5-9203-4fb61a36e780/assets/BriberyAct2010_UK.pdf',
    id: '01903334-3553-70e5-9203-4fb61a36e780',
    created_at: '2024-06-20T01:12:58.352186',
    updated_at: '2024-06-20T01:31:27.642975',
    docs: ['01903334-a10c-7e92-b195-066b3d0806f1'],
    name: 'Bribery Act 2010',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'QMUL Anti Bribery And Corruption Policy',
    url: '/policies/01903368-fcfd-7a7b-84a5-61749f65ef80/assets/Anti-Bribery-and-Corruption-Policy-updated-June-2021.pdf',
    id: '01903368-fcfd-7a7b-84a5-61749f65ef80',
    created_at: '2024-06-20T02:10:37.338606',
    updated_at: '2024-06-20T02:17:10.678090',
    docs: ['01903369-4e61-7224-8ee0-faea408b426b'],
    name: 'QMUL Anti-Bribery Policy',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'Kyc India 2024',
    url: '/policies/01903642-2a79-7e32-8abd-314059aa8796/assets/Kyc India 2024.pdf',
    id: '01903642-2a79-7e32-8abd-314059aa8796',
    created_at: '2024-06-20T15:27:04.768310',
    updated_at: '2024-06-20T16:57:39.798869',
    docs: ['01903642-cd88-7d00-a338-e519a8d5a117'],
    name: 'Kyc India 2024',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: null,
    description: 'UK AML 2017',
    url: '/policies/019036b1-b457-79b6-b970-0405af464df4/assets/AML 2017 copy.pdf',
    id: '019036b1-b457-79b6-b970-0405af464df4',
    created_at: '2024-06-20T17:28:54.686341',
    updated_at: '2024-06-20T20:05:53.790966',
    docs: ['019036b2-d84d-7aba-b1ec-c7300bac573f'],
    name: 'UK AML 2017',
    extra: {
      crossmap: {
        doc_to_ref: {},
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
  {
    reference_docs: ['01900996-a013-7686-a3d0-7673030fc1a9'],
    description: "Sainsbury's Supermarkets UK",
    url: '/policies/0190099a-e045-773b-bd8a-46c25ee2f8ff/assets/sainsburys-cookie-policy.pdf',
    id: '0190099a-e045-773b-bd8a-46c25ee2f8ff',
    created_at: '2024-06-11T23:21:03.688045',
    updated_at: '2024-06-11T23:28:43.559421',
    docs: ['0190099b-4842-761e-b647-a1cab2f9965f'],
    name: "Sainsbury's Cookie Policy",
    extra: {
      crossmap: {
        doc_to_ref: {
          '0190099b-4842-761e-b647-a1cab2f9965f_3_5': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_42',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_74',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_30',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_46',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_2',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_76',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_42',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_84',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_30',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_16',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_29',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_52',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_20',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_18',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_13',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_18',
          ],
          '0190099b-4842-761e-b647-a1cab2f9965f_4_3': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_30',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_36',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_13',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_18',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_33',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_46',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_52',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_42',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_76',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_84',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_96',
          ],
          '0190099b-4842-761e-b647-a1cab2f9965f_4_9': ['01900996-a013-7686-a3d0-7673030fc1a9_5_8'],
          '0190099b-4842-761e-b647-a1cab2f9965f_3_18': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_2',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_20',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_18',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_84',
          ],
          '0190099b-4842-761e-b647-a1cab2f9965f_3_20': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_2',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_20',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_36',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_13',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_18',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_29',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_46',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_12',
          ],
          '0190099b-4842-761e-b647-a1cab2f9965f_3_34': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_20',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_2',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_84',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_36',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_18',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_13',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_76',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_42',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_96',
          ],
          '0190099b-4842-761e-b647-a1cab2f9965f_4_15': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_36',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_42',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_33',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_8',
          ],
          '0190099b-4842-761e-b647-a1cab2f9965f_4_25': [
            '01900996-a013-7686-a3d0-7673030fc1a9_2_0',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_50',
            '01900996-a013-7686-a3d0-7673030fc1a9_2_90',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_2',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_16',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_20',
            '01900996-a013-7686-a3d0-7673030fc1a9_3_30',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_13',
            '01900996-a013-7686-a3d0-7673030fc1a9_4_18',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_42',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_84',
            '01900996-a013-7686-a3d0-7673030fc1a9_5_76',
          ],
        },
      },
    },
    is_regulation: null,
    user_id: '442f478b-3067-4ef3-bbba-6a12f3c58b08',
    status: 'processed',
    public: null,
  },
];

export function modifyData() {
  return policeData.map((item, index) => ({
    id: item.id,
    name: item.description,
    url: item.url,
    size: (index + 1) * 500,
    created_at: item.created_at,
    updated_at: item.updated_at,
    type: item.description,
  }));
}

export function dummyData() {
  return policeData.map((item, index) => ({
    id: item.id,
    name: item.description,
    type: item.description,
  }));
}
