import { paths } from 'src/routes/paths';

import { CONFIG } from 'src/config-global';

import { SvgColor } from 'src/components/svg-color';
import { Iconify } from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`${CONFIG.site.imageBasePath}/assets/icons/navbar/${name}.svg`} />
);

const ICONS = {
  job: icon('ic-job'),
  blog: icon('ic-blog'),
  risk: icon('ic-risk'),
  task: icon('ic-task'),
  chat: icon('ic-chat'),
  mail: icon('ic-mail'),
  user: icon('ic-user'),
  file: icon('ic-file'),
  lock: icon('ic-lock'),
  tour: icon('ic-tour'),
  order: icon('ic-order'),
  label: icon('ic-label'),
  blank: icon('ic-blank'),
  kanban: icon('ic-kanban'),
  folder: icon('ic-folder'),
  course: icon('ic-course'),
  banking: icon('ic-banking'),
  booking: icon('ic-booking'),
  invoice: icon('ic-invoice'),
  product: icon('ic-product'),
  calendar: icon('ic-calendar'),
  disabled: icon('ic-disabled'),
  external: icon('ic-external'),
  menuItem: icon('ic-menu-item'),
  ecommerce: icon('ic-ecommerce'),
  analytics: icon('ic-analytics'),
  dashboard: icon('ic-dashboard'),
  parameter: icon('ic-parameter'),
};
const iconSolar = (name) => <Iconify icon={name} />;
const ICONS_SOLAR = {
  dashboard: iconSolar('solar:home-angle-outline'),
  chat: iconSolar('solar:chat-line-bold'),
  tour: iconSolar('solar:notification-unread-lines-outline'),
  menuItem: iconSolar('solar:posts-carousel-vertical-outline'),
  file: iconSolar('solar:book-bookmark-outline'),
  lock: iconSolar('solar:lock-line-bold'),
  task: iconSolar('solar:plate-outline'),
  folder: iconSolar('solar:notebook-bookmark-outline'),
  risk: iconSolar('solar:shield-warning-outline'),
  control: iconSolar('solar:widget-6-outline'),
  impactAssessment: iconSolar('solar:folder-with-files-outline'),
  zangoCopilotBeta: iconSolar('solar:chat-line-bold'),
};
// ----------------------------------------------------------------------

export const navData = [
  /**
   * Overview
   */
  {
    subheader: 'subheader',
    items: [
      { title: 'dashboard', path: paths.dashboard.root, icon: ICONS_SOLAR.dashboard },
      { title: 'zangoCopilot', path: paths.dashboard.askZango, icon: ICONS.chat },
      { title: 'alertsTitle', path: paths.dashboard.alerts, icon: ICONS_SOLAR.tour },
      { title: 'gapAnalysis', path: paths.dashboard.gapAnalysis, icon: ICONS_SOLAR.menuItem },
      { title: 'lawLibrary', path: paths.dashboard.lawLibrary, icon: ICONS_SOLAR.file },
      {
        title: 'internalPolicies',
        path: paths.dashboard.internalPolicies,
        icon: ICONS_SOLAR.folder,
      },
      { title: 'risk', path: paths.dashboard.risk, icon: ICONS_SOLAR.risk },
      { title: 'control', path: paths.dashboard.control, icon: ICONS_SOLAR.control },
      { title: 'tasks', path: paths.dashboard.task, icon: ICONS_SOLAR.task },
      {
        title: 'impactAssessment',
        path: paths.dashboard.impactAssesment,
        icon: ICONS_SOLAR.impactAssessment,
      },
      { title: 'zangoCopilotBeta', path: paths.dashboard.zangoBeta, icon: ICONS.chat },
    ],
  },
  /**
   * Management
   */
  // {
  //   subheader: 'Management',
  //   items: [
  //     {
  //       title: 'Group',
  //       path: paths.dashboard.group.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: paths.dashboard.group.root },
  //         { title: 'Five', path: paths.dashboard.group.five },
  //         { title: 'Six', path: paths.dashboard.group.six },
  //       ],
  //     },
  //   ],
  // },
];
