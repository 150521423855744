import { create } from 'zustand';
import axiosInstance from 'src/utils/axios';
import { CONFIG } from 'src/config-global';

// const GEMINI_API_KEY = 'AIzaSyAxn0CE4J2RJv0vL1HYjw5Q5DSeG1bJBSA';
// const GEMINI_API_URL =
//   'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-pro-002:streamGenerateContent';

const createGeminiChatStore = (set, get) => ({
  isConnected: false,
  messages: [],
  isLoading: false,
  isExpanded: false,
  isChatActive: true,
  selectedOption: null,
  suggestedQuestions: [],
  input: '',
  imageData: null,

  setLoading: (isLoading) => set({ isLoading }),
  setIsExpanded: (value) => set({ isExpanded: value }),
  setIsChatActive: (value) => set({ isChatActive: value }),
  setSelectedOption: (option) => set({ selectedOption: option }),
  setInput: (value) => set({ input: value }),
  setImageData: (data) => set({ imageData: data }),

  resetChatBeta: () => {
    set({ messages: [], input: '', isConnected: false });
  },

  initializeWebSocket: () => {
    // Real connection logic will go here
    set({ isConnected: true });
  },

  streamResponse: async (prompt, imageData) => {
    set({ isLoading: true });
    try {
      const response = await fetch(
        `${CONFIG.site.geminiApiUrl}?alt=sse&key=${CONFIG.site.geminiApiKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'models/gemini-1.5-pro-002',
            contents: [
              {
                parts: imageData
                  ? [
                      { inline_data: { mime_type: 'image/png', data: imageData.encodedImage } },
                      { text: prompt },
                    ]
                  : [{ text: prompt }],
              },
            ],
            cachedContent: 'cachedContents/rfs6ulkh1qak',
            generationConfig: {
              maxOutputTokens: '8192',
              temperature: 0.6,
              topP: 1,
            },
          }),
        }
      );
      //   Can you analze the image above and explain if it complies with social media promotion guidelines? Provide citations from the references guidelines. First answer the question. Provide citations in the end. Remember, you must return both an answer and citations. A citation consists of a VERBATIM quote that justifies the answer.
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.candidates?.[0]?.content?.parts?.[0]?.text) {
                accumulatedResponse += data.candidates[0].content.parts[0].text;
                set((state) => {
                  const newMessages = [...state.messages];
                  newMessages[newMessages.length - 1].message = accumulatedResponse;
                  return { messages: newMessages };
                });
              }
            } catch (e) {}
          }
        }
      }
    } catch (error) {
    } finally {
      set({ isLoading: false });
    }
  },

  sendMessage: async (message, imageData = null, user) => {
    if (!message.trim()) return;

    // Add user message and empty bot message
    set((state) => ({
      messages: [
        ...state.messages,
        {
          sender: 'user',
          message,
          timestamp: new Date(),
          image: imageData ? imageData : null,
        },
        {
          sender: 'bot',
          message: '',
          timestamp: new Date(),
        },
      ],
      input: '',
      imageData: null,
    }));

    // Start streaming response
    get().streamResponse(message, imageData);
  },

  handleRelatedQuestionClick: (question) => {
    get().sendMessage(question, {});
  },

  fetchSuggestedQuestions: async (id, type) => {
    try {
      const { data } = await axiosInstance.get(
        `/chat/questions${id ? (type === 'doc_id' ? `?doc_id=${id}` : `?alert_id=${id}`) : ''}`
      );
      set({ suggestedQuestions: data });
    } catch (error) {
      set({ suggestedQuestions: [] });
    }
  },
});

// export const useChatStore = create((set, get) => createGeminiChatStore(set, get));
export const useChatStoreBeta = create((set, get) => createGeminiChatStore(set, get));
