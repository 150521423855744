import { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Iconify } from 'src/components/iconify';
import { Form } from 'src/components/hook-form';
import { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import UploadFile from './upload-file';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'src/components/snackbar';
import useLawLibraryStore from 'src/zustand-store/law-library-store/law-library-store';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

// Simplified schema
const UploadLegislationSchema = zod.object({
  name: zod.string().min(1, 'Name is required'),
  description: zod.string().optional(),
  files: zod.any().array().min(1, { message: 'At least one PDF file is required!' }),
  extract_rules: zod.boolean(),
  is_regulation: zod.boolean(),
});

export default function UploadLegislation({ onFileUpload }) {
  const [open, setOpen] = useState(false);
  const { createNewPolicy, createPolicy, setCreatePolicy } = useLawLibraryStore();
  const [uploadProgress, setUploadProgress] = useState(0);
  const methods = useForm({
    mode: 'all',
    resolver: zodResolver(UploadLegislationSchema),
    defaultValues: {
      name: '',
      description: '',
      files: [],
      extract_rules: true,
      is_regulation: true,
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { isSubmitting, isValid },
  } = methods;

  const recheckValidation = useCallback(async () => {
    await trigger();
  }, [trigger]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Set loading state

      const formData = new FormData();
      // Append file from the files array
      formData.append('file', data.files[0]);
      // Append other form fields
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('extract_rules', data.extract_rules);
      formData.append('is_regulation', data.is_regulation);

      const response = await createNewPolicy(formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 95;
          setUploadProgress(Math.round(progress));
        },
      });

      setTimeout(() => {
        handleClose();
      }, 1000);
      // toast.success('Upload successful!');
    } catch (error) {
      // Set error state
      // toast.error('Upload failed');
    }
  });

  const handleClose = () => {
    setCreatePolicy({
      isLoading: false,
      data: null,
      error: null,
      success: null,
    });
    setOpen(false);
    reset();
  };

  const handleFileChange = (file) => {
    setValue('files', [file]);
    if (getValues('name') === '') {
      // Remove file extension and replace underscores with spaces
      const nameWithoutExtension = file?.name ? file?.name?.split('.')[0].replaceAll('_', ' ') : '';
      setValue('name', nameWithoutExtension);
    }
    recheckValidation();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<Iconify icon="mdi:upload" />}
      >
        Upload Legislation
      </Button>

      <Dialog
        open={open}
        maxWidth={false}
        disableEscapeKeyDown
        hideBackdrop={false}
        sx={{
          '& .MuiDialog-paper': { width: '480px', justifyContent: 'center', minHeight: '230px' },
        }}
      >
        <Form methods={methods} onSubmit={onSubmit}>
          {!(createPolicy.error || createPolicy.success || createPolicy.isLoading) && (
            <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
              Upload Legislation
            </DialogTitle>
          )}

          <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
            {/* Initial upload form - Show when not uploading and no result yet */}
            {!(createPolicy.error || createPolicy.success || createPolicy.isLoading) && (
              <Stack spacing={3}>
                <UploadFile onFileChange={handleFileChange} />
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Name"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
            )}

            {/* Upload progress - Show when uploading is in progress */}
            {createPolicy.isLoading && !createPolicy.success && !createPolicy.error && (
              <Stack spacing={2} direction="column" align="center" alignItems="center">
                <Iconify
                  icon="solar:file-send-bold-duotone"
                  color="primary.dark"
                  sx={{ width: 48, height: 48 }}
                />
                <Stack
                  direction="row"
                  align="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ width: '80%', mx: 'auto' }}
                >
                  <LinearProgress variant="determinate" value={uploadProgress} sx={{ flex: 1 }} />
                  <Typography variant="caption">{uploadProgress}%</Typography>
                </Stack>
                <Typography
                  align="center"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: 'var(--subtitle2line-height)',
                    letterSpacing: 'var(--subtitle2letter-spacing)',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color: 'var(--text-primary, #1C252E)',
                  }}
                >
                  Uploading Document...
                </Typography>
                <Typography
                  align="center"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: 'var(--subtitle2line-height)',
                    letterSpacing: 'var(--subtitle2letter-spacing)',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color: 'var(--text-secondary, #637381)',
                  }}
                >
                  Will generate summary and relevant information very soon
                </Typography>
              </Stack>
            )}

            {/* Success state - Show when upload is complete */}
            {!createPolicy.isLoading && createPolicy.success && !createPolicy.error && (
              <Stack alignItems="center" spacing={2}>
                <Iconify
                  icon="solar:file-check-bold-duotone"
                  color="success.dark"
                  sx={{ width: 48, height: 48 }}
                />

                {/* <Box component="img" src="/assets/images/custom-icon/file_success.svg" sx={{ width: 36, height: 36 }} /> */}
                <Typography
                  align="center"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: 'var(--subtitle2line-height)',
                    letterSpacing: 'var(--subtitle2letter-spacing)',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color: 'var(--success-dark, #118D57)',
                  }}
                >
                  Successfully Uploaded
                </Typography>
              </Stack>
            )}

            {/* Error state - Show when upload failed */}
            {!createPolicy.isLoading && !createPolicy.success && createPolicy.error && (
              <Stack alignItems="center" spacing={2}>
                <Iconify
                  icon="solar:file-send-bold-duotone"
                  color="error.dark"
                  sx={{ width: 48, height: 48 }}
                />
                {/* <Box component="img" src="/assets/images/custom-icon/file_error.svg" sx={{ width: 36, height: 36 }} /> */}
                <Typography
                  align="center"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: 'var(--subtitle2line-height)',
                    letterSpacing: 'var(--subtitle2letter-spacing)',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color: 'var(--error-dark, #E53935)',
                  }}
                >
                  Upload Failed
                </Typography>
                <Typography
                  align="center"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: 'var(--subtitle2line-height)',
                    letterSpacing: 'var(--subtitle2letter-spacing)',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color: 'var(--text-secondary, #637381)',
                  }}
                >
                  Failed to upload document, an error occurred on the server
                </Typography>
                <Button variant="contained" sx={{ width: '210px' }} onClick={handleClose}>
                  Close
                </Button>
              </Stack>
            )}
          </DialogContent>

          {!(createPolicy.error || createPolicy.success || createPolicy.isLoading) && (
            <DialogActions>
              {/* Only show actions when in initial state */}

              <Box display="flex" justifyContent="space-between" width="100%" spacing={2}>
                <Button
                  variant="outlined"
                  sx={{ width: '49%' }}
                  color="inherit"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  sx={{ width: '49%' }}
                  variant="contained"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingIndicator="Submitting..."
                >
                  Submit
                </LoadingButton>
              </Box>
            </DialogActions>
          )}
        </Form>
      </Dialog>
    </>
  );
}
